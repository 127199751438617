// import { HIDESTATUS, SHOWSTATUS } from "../../types";

import { HIDESTATUS, SHOWSTATUS } from ".";

export const apiConsumeMesage = (res, values) => dispatch => {
  let msg = "Operation failed.";
  let status = 200;
  let payload = null;
  let response = res;

  if (res.response) {
    // For Error
    response = res.response;
    msg = response.statusText;
  }

  status = response.status;

  // console.log(response.statusText);

  payload = response.data;
  if (payload && payload.message) msg = payload.message;

  console.log(payload);

  let dataValues = {
    show: true,
    message:
      values === "login" && status === 200
        ? "Successfully logged in"
        : values === "register" && status === 200
        ? "Successfully registered"
        : values === "account_verify" && status === 200
        ? "Successfully verified"
        : values === "update-account" && status === 200
        ? "Successfully Update account details"
        : values === "createBusiness" && status === 200
        ? "Sucessfully created business"
        : values === "uploadKYC" && status === 200
        ? "Successfully uploaded KYC and personal documents"
        : values === "addBusiness" && status === 200
        ? "Successfully added new business"
        : values === "addBank" && status === 200
        ? "Successfully update bank"
        : values === "payInterface" && status === 200
        ? "Successfully created a new payment interface"
        : values === "editPayLink" && status === 200
        ? "Successfully edited payment link."
        : values === "changePassword" && status === 200
        ? "Successfully changed password"
        : values === "businessSettingUpdate" && status === 200
        ? "Successfully updated settings"
        : values === "refund" && status === 200
        ? "Successful. currently processing request"
        : values === "inviteUser" && status === 200
        ? "Successfully sent invite"
        : values === "editBusiness" && status === 200
        ? "Successfully edit business"
        : values === "editCompany" && status === 200
        ? "Successfully edit company"
        : values === "addMFA" && status === 200
        ? "Successfully added two factor auth"
        : values === "deleteKyc" && status === 200
        ? "Successfully deleted kyc"
        : values === "deleteInvite" && status === 200
        ? "Successfully deleted invite"
        : values === "addCurrency" && status === 200
        ? "Successfully added currency to business"
        : values === "editCurrency" && status === 200
        ? "Successfully edited currency"
        : values === "updateTeamMember" && status === 200
        ? "Successfully edited user status"
        : values === "delSubAccount" && status === 200
        ? "Successfully deleted account"
        : values === "addSubAccount" && status === 200
        ? "Successfully added account"
        : values === "createSplit" && status === 200
        ? "Successfully created split"
        : values === "updateChannel" && status === 200
        ? "Successfully update channel"
        : values === "createChannel" && status === 200
        ? "Successfully created channel"
        : values === "senderid" && status === 200
        ? "Succssfully created sender id"
        : values === "createCampaign" && status === 200
        ? "Successfully created campaign"
        : values === "updateCampaign" && status === 200
        ? "Successfully updated campaign"
        : values === "update-profile" && status === 200
        ? "Successfully updated user"
        : values === "update-company" && status === 200
        ? "Successfully updated company"
        : values === "create_addgroup" && status === 200
        ? "Successfully created group"
        : values === "edit_addgroup" && status === 200
        ? "Successfully updated address group"
        : values === "reset-password" && status === 200
        ? "Successfully reset password"
        : values === "update-invite-stats" && status === 200
        ? "Successfully accepted invite"
        : values === "verify_success" && status === 200
        ? "Successfully verified"
        : msg === "Operation failed."
        ? ""
        : msg,
    statusCode: status,
    success: status < 400
  };

  // console.log(status);

  if (status === 500) {
    window.location.href = `${process.env.REACT_APP_ROUTEDATA}/errors/error-500`;
  }

  if (status === 404) {
    window.location.href = `${process.env.REACT_APP_ROUTEDATA}/errors/error-404`;
  }

  dispatch({ type: SHOWSTATUS, payload: dataValues });

  setTimeout(() => {
    dispatch({ type: HIDESTATUS });
  }, 6000);
};
