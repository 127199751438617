import React from 'react'

export default function Terms() {
    return (
        <div className={`post_gallary_area mb40`}>
            <div className="container">
                <h2>Our Terms and condition</h2>
                <div className='mt-3 mb-3'>
                    <p>These terms and conditions govern your use of Reload.ng ("Service") provided by Wifitracker. By using the Service, you agree to comply with these Terms. Please read them carefully.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Service Description:</b></p>
                    <p>The Service allows users to make payments for bills electronically through Reload.ng platform.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Registration:</b></p>
                    <p>Reload.ng platform accomodated guest users for faster and seamless transaction flow.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Authorized Payments:</b></p>
                    <p>By using the Service, you authorize Reload.ng to initiate payments on your behalf to the designated payees through payment solutions or through our wallet.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Payment Processing:</b></p>
                    <p>Payments processed via the platform are completed almost instantly.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Fees:</b></p>
                    <p>Reload.ng may charge fees for the use of the Service. Any applicable fees will be disclosed to you prior to initiating a payment.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Security:</b></p>
                    <p>You are responsible for maintaining the security of your account credentials and for any activities conducted under your account.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Accuracy of Information:</b></p>
                    <p>You agree to provide accurate and up-to-date information when making payments through the Service. Reload.ng is not responsible for any errors resulting from inaccurate information provided by you.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Limitation of Liability:</b></p>
                    <p>Reload.ng shall not be liable for any damages or losses arising from the use of the Service, except where such damages or losses are caused by Reload.ng's gross negligence or willful misconduct.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Indemnification:</b></p>
                    <p>You agree to indemnify and hold harmless Reload.ng from any claims, damages, or losses arising from your use of the Service or your breach of these Terms.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Termination:</b></p>
                    <p>Reload.ng reserves the right to terminate or suspend your access to the Service at any time, with or without cause, and without prior notice.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Modification of Terms:</b></p>
                    <p>Reload.ng reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the [Your Company Name] website.</p>
                </div>
                <div className='mb-3'>
                    <p><b>Governing Law:</b></p>
                    <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
                </div>
            </div>
        </div>
    )
}
