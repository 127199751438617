import React, { useEffect, useState } from 'react'
import { FormComponent } from '../FormComponent'
import { dataValues } from '../../utils/dataValues'
import { Button, Card, FormGroup, Spinner } from 'reactstrap'
import { resetPassword } from '../../store/actions/agentAuth'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import "./style.scss"

export default function AgentReset(props) {
	const dispatch = useDispatch()
	const [values, setValues] = useState({})
	const [loader, setLoader] = useState(false)
	const [uid, setUID] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const resetErr = useSelector(state => state.agentUser.resetErr)

	useEffect(() => {
		const params = new URLSearchParams(props.location.search);
		const productKey = params.get("uid64");

		setUID(productKey);
	}, []);

	const handleChange = (e, name) => {
		const newValues = { ...values }
		newValues[name] = e.target.value
		setValues(newValues)
	}

	const onFormSubmit = e => {
		e.preventDefault()

		if (values["r-password"] && values["r-cmPassword"]) {
			setLoader(true);

			const data = {
				password: values["r-password"],
				uid: uid,
			};

			dispatch(resetPassword(data));
		} else {
			setLoader(false);
			toast.error("Verify Passwords are the same")
			// const dataValues = { message: "Verify Passwords are the same", statusCode: 400, success: false };
			// dispatch({ type: SHOWSTATUS, payload: dataValues });
		}
	}

	const showHide = (e) => {
		e.preventDefault()

		setShowPassword(!showPassword)
	}

	useEffect(() => {
		if (resetErr) {
			setLoader(false)
		}
	}, [resetErr])

	return (
		<div className='resetPadd'>
			<div className='card p-3'>
				<FormGroup>
					<h4>Reset Password</h4>
					{dataValues.reset.map((allData, i) => (
						<div key={i} className='p-3'>
							<label className="password">{allData.name}
								<input type={showPassword ? "text" : allData.type} className="password__input" onChange={e => handleChange(e, allData.code)} placeholder={allData.placeHolder} />
								<span className="password__show" onClick={showHide}>{showPassword ? 'Hide' : 'Show'}</span>
							</label>
							{/* <FormComponent
						data={dataValues.reset}
						values={values} // Use optional chaining here
						handleChange={handleChange}
						loading={loader}
						handleDone={onFormSubmit}
						ButtonName={"Submit"}
					/> */}
						</div>
					))}
				</FormGroup>

				<FormGroup>
					<Button disabled={loader ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" style={{ backgroundColor: "#FFCC28", borderRadius: "1px solid #FFCC28" }}>
						{loader ? <Spinner size="sm" color="light" /> : "Submit"}
					</Button>
				</FormGroup>
			</div>
		</div>
	)
}
