import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap'
import { txQuery, txStatusQuery } from '../../store/actions/Coralpay';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { requeryTxn } from '../../store/actions/finalPayment';

export default function Processing(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [timer, setTimer] = useState(true)
    const [uid, setUID] = useState(null);
    const [status, setStatus] = useState(false)
    const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query)

    useEffect(() => {
        if (tranStatus_query) {
            if (tranStatus_query.data.status === 1) {
                setTimer(false)
                history.push(`${process.env.PUBLIC_URL}/stat/receipt`)
                setStatus(true)
            } else {
                history.push(`${process.env.PUBLIC_URL}/payment-failed`)
            }
        } else {
            history.push(`${process.env.PUBLIC_URL}/payment-failed`)
        }
    }, [tranStatus_query])

    useEffect(() => {
        if (timer) {
            // console.log("Effect started");
            const interval = setInterval(() => {
                console.log("Interval triggered");
                const params = new URLSearchParams(props.location.search);
                const id = params.get("id");
                const status = params.get("status");

                // Dispatch the txQuery action to query the backend
                localStorage.setItem("ref", id)
                dispatch(requeryTxn(id))
                dispatch(txStatusQuery(id));
            }, 2000); // Query every 2 seconds

            // Cleanup function to clear the interval when component unmounts
            return () => {
                console.log("Cleanup function called");
                clearInterval(interval);
            };
        }
    }, [timer]); // Include relevant dependencies

    return (
        <div style={{ margin: "60px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}><Spinner size="lg" color="dark" /></div>
            <h4 style={{ display: "flex", justifyContent: "center" }}>processing....</h4>
        </div>
    )
}
