import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { dataValues } from '../../utils/dataValues';
import { CircleLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import Icon from '../icon/Icon';
import { hideModal } from '../../store/actions/modalSetup';

export default function AgentRegister({ loader, setLoader, register, setRegister, handleChange, submitModalFunc, getValue }) {
	const dispatch = useDispatch()
	return (
		<div>
			<Modal isOpen={register} toggle={() => setRegister(false)}>
				<ModalHeader>Register</ModalHeader>
				<ModalBody>
					<a
						href="#cancel"
						onClick={(ev) => {
							ev.preventDefault();
							setRegister(false)
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<div>{dataValues.registerData.map((allData, i) => (
							// <FormGroup key={i}>
							<div
								className="form-control-wrap"
								key={i}
								style={{ display: "flex", justifyContent: "center" }}
							>
								<input
									type={allData.type}
									id={allData.code}
									name={allData.code}
									value={getValue(allData.code)}
									required={allData.required}
									placeholder={allData.placeHolder}
									onChange={(e) => handleChange(e, allData.code)}
									className={`form-control-lg form-control`}
								/>
							</div>
							// </FormGroup>
						))}</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={(e) => {
							e.preventDefault();
							// dispatch(hideModal());
							setRegister(false)
							setLoader(false);
						}}
					>
						Cancel
					</Button>
					{loader ? (
						<Button disabled={true}>
							<CircleLoader size={30} color="#2E0968" />
						</Button>
					) : (
						<Button
							style={{
								background: "#fda94f",
								borderRadius: "1px solid #fda94f",
								color: "#000",
							}}
							onClick={submitModalFunc}
						>
							Submit
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</div>
	)
}
