import {
  USER_LOADING,
  USER_LOADED,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGIN_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_FAIL,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_FAILED,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  CONTACTUS_ERROR,
  CONTACTUS,
  SUBSCRIBERS,
  SUBSCRIBERS_ERROR,
  THROW_SUCCESS,
  THROW_ERROR,
} from "../constants/index.js";
import axios from "axios";
import { apiConsumeMesage } from "../constants/messageSlice.js";
import { configJson } from "../constants/headers.js";
import { userWallet } from "./wallet.js";
import { toast } from "react-toastify";
// import { returnErrors } from "../constants/index.js";
// import { hideLoader } from "./loading";

export const getUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  axios
    .get(
      `${process.env.REACT_APP_API}/api/auth/user`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(userWallet(res.data.user.id))
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

export const contactUs = (values) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/contactus`,
      values,
      config
    )
    .then((res) => {
      // dispatch(hideLoader())Loader());
      return dispatch({
        type: CONTACTUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTACTUS_ERROR,
      });
    });
};

export const unSubscribers = (values) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/unsubscribe`,
      values,
      config
    )
    .then((res) => {
      // dispatch(hideLoader())Loader());
      dispatch({
        type: THROW_SUCCESS,
        payload: res.data.message,
      });
      dispatch({
        type: SUBSCRIBERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: THROW_ERROR,
        payload: err.response.data.message,
      });
      dispatch({
        type: SUBSCRIBERS_ERROR,
        payload: err.response.data.message
      });
    });
};

// register user
export const RegUser = (values) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  var body = JSON.stringify(values);

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/mobile/reg`,
      values,
      config
    )
    .then((res) => {
      // dispatch(hideLoader())Loader());
      if(res.status === 200) {
        dispatch(userWallet(res.data.user.id))
        return dispatch({
          type: REGISTER_USER,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

export const LoginUser = (user) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/mobile/login`,
      user,
      config
    )
    .then((res) => {
      // console.log(res)
      // dispatch(hideLoader())Loader());
      if (res.status === 200) {
        // dispatch(getUser())
        console.log(res.data.user.id)
        localStorage.setItem("userId", res.data.user.id)
        dispatch(userWallet(res.data.user.id))
        return dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAILED,
        payload: err.response
      });
    });
};

export const forgotPassword = (values) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  // var body = JSON.stringify(values);

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/forgotpassword`,
      values,
      config
    )
    .then((res) => {
      // dispatch(hideLoader())Loader());
      // if (res.status === 200) {
      return dispatch({
        type: FORGOT_PASSWORD,
        payload: res.data,
      });
      // }
    })
    .catch((err) => {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      });
    });
};

export const resetPassword = (user) => (dispatch) => {
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_API}/api/billercustomer/resetpassword`,
      user,
      config
    )
    .then((res) => {
      // dispatch(hideLoader())Loader());
      if (res.status === 200) {
        toast.success("Password successfully reset")
        return dispatch({
          type: RESET_PASSWORD,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: err.response
      });
      // }
    });
};

export const updateProfile = (newDetails) => (dispatch, getState) => {
  const phone = newDetails.phone;
  const fullname = newDetails.fullname;
  const address = newDetails.address;

  const body = {};
  const updateDetails = Object.assign(body, { phone, fullname, address });

  axios
    .put(
      `${process.env.REACT_APP_API}/api/billercustomer/update/${newDetails.customerId}`,
      updateDetails,
      secondTokenConfig(getState)
    )
    .then((res) => {
      if (res.status === 200) {
        return (
          dispatch({
            type: UPDATE_PROFILE,
            payload: res.data,
          }),
          setTimeout(() => {
            if (localStorage.token) {
              // dispatch(hideLoader())Loader());
            }
          }, 3000)
        );
      }
    })
    .catch((err) => {
      // dispatch(hideLoader())Loader());
      // if (err.response.status === 500) {
      //   window.location.href = `/error/process`;
      // } else {
      //   dispatch(
      //     returnErrors(err.response.data, err.response.status, "LOGIN_FAILED")
      //   );
      dispatch({
        type: UPDATE_PROFILE_ERROR,
      });
      // }
    });
};

export const ChangeuserPassword = (newDetails) => (dispatch, getState) => {
  const oldPassword = newDetails.oldPassword;
  const newPassword = newDetails.NewPassword;

  const body = {};
  const passwordDetails = Object.assign(body, { oldPassword, newPassword });
  // console.log(passwordDetails);
  axios
    .put(
      `${process.env.REACT_APP_API}/api/billercustomer/changepassword/${newDetails.customerId}`,
      passwordDetails,
      secondTokenConfig(getState)
    )
    .then((res) => {
      if (res.status === 200) {
        return (
          dispatch({
            type: PASSWORD_CHANGED,
            payload: res.data,
          }),
          setTimeout(() => {
            if (localStorage.token) {
              // dispatch(hideLoader())Loader());
            }
          }, 3000)
        );
      }
    })
    .catch((err) => {
      // dispatch(hideLoader())Loader());
      // if (err.response.status === 500) {
      //   window.location.href = `/error/process`;
      // } else {
      //   dispatch(
      //     returnErrors(
      //       err.response.data,
      //       err.response.status,
      //       "PASSWORD_CHANGE_FAILED"
      //     )
      //   );
      dispatch({
        type: PASSWORD_CHANGE_FAILED,
      });
      // }
    });
};

export const handleGoogle = userData => dispatch => {
  // try {
  axios
    .post(`${process.env.REACT_APP_API}/api/billercustomer/mobile/google`, userData, configJson)
    .then(res => {
      console.log("Registration response:", res.data);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch(apiConsumeMesage(res, "login"));
    })
    .catch(err => {
      dispatch({ type: LOGIN_FAILED, payload: err });
      // dispatch(apiConsumeMesage(res, "login"));
      // console.error("Registration error:", err);
    });
};

export const logout = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const tokenConfig = (getState) => {
  // Get token from localstorage
  const token = getState().authUser.token;

  // set Header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // if token, add to header
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const secondTokenConfig = (getState) => {
  // Get token from localstorage
  const token = getState().authUser.token;

  // set Header
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  // if token, add to header
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};
