import { AGENT_LOGERROR, AGENT_LOGIN, AGENT_REGERROR, AGENT_REGISTER, AUTH_AGENT_ERROR, FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, RESET_PASSWORD, RESET_PASSWORD_FAIL, UPDATE_PROFILE, UPDATE_PROFILE_ERROR, USER_AGENT_LOADED, USER_AGENT_LOADING } from "../constants";

const initialState = {
	token: localStorage.getItem("agent_token"),
	reset: null,
	resetErr: null,
	data: null,
	forgotPass: null,
	forgotPassErr: null,
	err: null,
	loginData: null,
	error: null,
	authError: null,
	getAgentData: null,
	loading: false,
	updateProfile: null,
	updateProfileErr: null
};

const agentUser = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD:
			return {
				...state,
				reset: action.payload
			}
		case RESET_PASSWORD_FAIL:
			return {
				...state,
				resetErr: action.payload
			}
		case AGENT_REGISTER:
			localStorage.setItem("agent_token", action.payload.token);
			return {
				...state,
				data: action.payload,
			};
		case FORGOT_PASSWORD:
			return {
				...state,
				forgotPass: action.payload
			}
		case FORGOT_PASSWORD_FAIL:
			return {
				...state,
				forgotPassErr: action.payload
			}
		case USER_AGENT_LOADING:
			return {
				...state,
				loading: true
			}
		case USER_AGENT_LOADED:
			return {
				...state,
				getAgentData: action.payload
			}
		case AUTH_AGENT_ERROR:
			return {
				...state,
				authError: action.payload
			}
		case AGENT_REGERROR:
			localStorage.removeItem("agent_token");
			return {
				...state,
				err: action.payload,
			};
		case AGENT_LOGIN:
			localStorage.setItem("agent_token", action.payload.token);
			return {
				...state,
				loginData: action.payload
			}
		case UPDATE_PROFILE:
			return {
				...state,
				updateProfile: action.payload
			}
		case UPDATE_PROFILE_ERROR:
			return {
				...state,
				updateProfileErr: action.payload
			}
		case AGENT_LOGERROR:
			localStorage.removeItem("agent_token");
			return {
				...state,
				error: action.payload
			}
		default:
			return state;
	}
};

export default agentUser;
