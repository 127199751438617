import React, { Fragment, useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, Fade, NavLink } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { hideModal, openModal, valueData } from "../../store/actions/modalSetup";
import { productNameData } from "../../store/actions/transaction";
import { selectProduct } from "../../store/actions/products";

const WidgetTabPane = ({ arr, a_id, id, dark }) => {
  const dispatch = useDispatch();
  const [img, setImg] = useState(null);
  const verifyError = useSelector(state => state.trans.error)

  const handleClick = (e, item) => {
    e.preventDefault();

    setImg(item.productId.logourl);
    let name = item.productId.productcategoryId.categoryname;
    let slug = item.billerCode;
    dispatch(productNameData(slug));
    let id = item.productId.id;
    let img = item.productId.logourl;
    dispatch(openModal(name, img));
    dispatch(valueData(id, slug));
    dispatch(selectProduct(item))
  };

  useEffect(() => {
    if (verifyError) {
      dispatch(hideModal())
    }
  }, [verifyError])

  return (
    <>
      <Fade in={id === a_id}>
        <div className="widget tab_widgets">
          <div>
            {/* {arr.slice(0, 8).map((item, i) => ( */}
            {arr.map((item, i) => (
              <Fragment key={i}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleClick(e, item)}
                  className="single_post widgets_small"
                >
                  <div className="post_img">
                    <div className="img_wrap">
                      <Link to="#">
                        <img src={item.productId.logourl} alt="thumb" />
                      </Link>
                    </div>
                  </div>
                  <div
                    style={{ paddingTop: "15px" }}
                    className="single_post_text"
                  >
                    <h4>{item.productId.productname}</h4>
                    <div className="meta2 meta_separator1">
                      <span>{item.productId.description}</span>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </Fade>
    </>
  );
};

const WidgetTab = ({ className, dark }) => {
  const productList = useSelector((state) => state.product.listProducts);
  const [activeTab, setActiveTab] = useState("1");
  const [electric, setElectric] = useState([]);
  const [cable, setCable] = useState([]);
  const [vtuData, setVtuData] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (productList.length > 0) {
      setElectric(prevElectric => [...prevElectric, ...productList.filter(allData => allData.productId.productcategoryId.categoryname === "Electricity")]);
      setCable(prevCable => [...prevCable, ...productList.filter(allData => allData.productId.productcategoryId.categoryname === "Cable TV")]);
      setVtuData(prevMobile => [...prevMobile, ...productList.filter(allData => allData.productId.productcategoryId.categoryname === "Mobile")]);
      setVtuData(prevNetwork => [...prevNetwork, ...productList.filter(allData => allData.productId.productcategoryId.categoryname === "Network")]);
    }
  }, [productList]);


  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
            style={{ backgroundColor: activeTab === "1" ? "#300B65" : "transparent", color: activeTab === "1" ? "#fff" : "#000" }} // Set background color for active tab
          >
            Electricity
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
            style={{ backgroundColor: activeTab === "2" ? "#300B65" : "transparent", color: activeTab === "2" ? "#fff" : "#000" }} // Set background color for active tab

          >
            Cable
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
            style={{ backgroundColor: activeTab === "3" ? "#300B65" : "transparent", color: activeTab === "3" ? "#fff" : "#000" }} // Set background color for active tab
          >
            Data and VTU
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <WidgetTabPane dark={dark} a_id={activeTab} id="1" arr={electric} />
        </TabPane>
        <TabPane tabId="2">
          <WidgetTabPane dark={dark} a_id={activeTab} id="2" arr={cable} />
        </TabPane>
        <TabPane tabId="3">
          <WidgetTabPane dark={dark} a_id={activeTab} id="3" arr={vtuData} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default WidgetTab;
