import { GET_AGENT_BALANCE, GET_AGENT_BALANCE_ERROR, WALLET_AGENT_FUNDING, WALLET_AGENT_FUNDING_ERROR } from "../constants";

const initialState = {
	walletFunding: null,
	fundingError: null,
	balance: null,
	balanceError: null
};

const agentFunding = (state = initialState, action) => {
	switch (action.type) {
		case WALLET_AGENT_FUNDING:
			return {
				...state,
				walletFunding: action.payload,
			};
		case WALLET_AGENT_FUNDING_ERROR:
			return {
				...state,
				fundingError: true
			}
		case GET_AGENT_BALANCE:
			return {
				...state,
				balance: action.payload
			}
		case GET_AGENT_BALANCE_ERROR:
			return {
				...state,
				balanceError: action.payload
			}
		default:
			return state;
	}
};

export default agentFunding;
