import React from 'react'
import "./style.scss"

const questions = [
	{
		id: 1,
		question: 'Popular Articles',
		answer: 'Suspendisse ipsum elit, hendrerit id eleifend at, condimentum et mauris. Curabitur et libero vel arcu dignissim pulvinar ut ac leo. In sit amet orci et erat accumsan interdum.',
	},
	{
		id: 2,
		question: 'Fix problems & request removals',
		answer: 'Suspendisse ipsum elit, hendrerit id eleifend at, condimentum et mauris. Curabitur et libero vel arcu dignissim pulvinar ut ac leo. In sit amet orci et erat accumsan interdum.',
	},
	{
		id: 3,
		question: 'Browse the web',
		answer: 'Suspendisse ipsum elit, hendrerit id eleifend at, condimentum et mauris. Curabitur et libero vel arcu dignissim pulvinar ut ac leo. In sit amet orci et erat accumsan interdum.',
	},
	{
		id: 4,
		question: 'Search on your phone or tablet',
		answer: 'Suspendisse ipsum elit, hendrerit id eleifend at, condimentum et mauris. Curabitur et libero vel arcu dignissim pulvinar ut ac leo. In sit amet orci et erat accumsan interdum.',
	},

]

const Question = props => {
	const [isActive, setActive] = React.useState(false);
	const [idVal, setIdVal] = React.useState(0)
	const handleClick = (id) => {
		// setActive(!isActive)
		setIdVal(id)
	}
	return (
		<>
			<div className="question-wrapper">
				<div className='question' id={1}>
					<h3>What is Reload.ng?</h3>
					<button onClick={() => handleClick(1)}>
						<svg className={idVal === 1 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 1 ? 'answer active' : 'answer'}>
					<p>Reload.ng offers you a convenient, secure way to pay for all your day-to-day services such as TV subscription (DStv, GOtv, StarTimes), airtime, data, electricity (IKEDC, AEDC, EKEDC, JEDC, PHEDC, KEDC, IBEDC, KAEDCO).</p>
					<p>By creating an account using just your email and phone number or by simply paying for a service, you can get started right now. Click here to Sign up</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={2}>
					<h3>Can I pay for a service without creating an account?</h3>
					<button onClick={() => handleClick(2)}>
						<svg className={idVal === 2 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 2 ? 'answer active' : 'answer'}>
					<p>It is optional to register. To make a payment, all you need is your email address, phone number, and debit card.</p>
					<p>Signing up is quick and easy. Here's what you need:</p>
					<ul>
						<li>You can visit <a href="https://www.reload.ng">Reload.ng</a>.</li>
						<li>To register, click the register button at the top of the page.</li>
						<li>Provide an email address, phone number, and password you can easily remember. Click on the sign up button.</li>
						<li>To make a payment, log into the reload.ng website using your login details.</li>
						<li>You can begin transacting immediately after creating your account.</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={3}>
					<h3>What is the procedure for resetting my password?</h3>
					<button onClick={() => handleClick(3)}>
						<svg className={idVal === 3 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 3 ? 'answer active' : 'answer'}>
					<p>You can reset your password by following these steps:</p>
					<ul>
						<li>Click on forgot password after entering your registered email address or phone number.</li>
						<li>You will receive a new password via email. Your password can be successfully reset by following the instructions in the password reset email you received.</li>
						<li>After logging in, we recommend you change your password to one you will easily remember.</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={4}>
					<h3>Is there a payment option for me?</h3>
					<button onClick={() => handleClick(4)}>
						<svg className={idVal === 4 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 4 ? 'answer active' : 'answer'}>
					<p>The following methods can be used to make payments:</p>
					<ul>
						<li>Payments can be made by debit card, USSD, bank transfer, Visa QR code, etc.</li>
						<li>Registered users can make payments through their wallets (Wallet payments are only available to registered users).</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={5}>
					<h3>What is the payment process for a service?</h3>
					<button onClick={() => handleClick(5)}>
						<svg className={idVal === 5 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 5 ? 'answer active' : 'answer'}>
					<p>The following methods can be used to make payments:</p>
					<ul>
						<li>Visit <a href="https://www.reload.ng">Reload.ng</a>.</li>
						<li>Choose the type of bill you would like to pay.</li>
						<li>After selecting the service, you wish to purchase  you will need to enter all necessary information on the transaction page. To proceed, click "Verify or pay".</li>
						<li>The confirmation page follows. To proceed with your purchase, select either a debit card, a bank transfer, a USSD, a visa QR, or a wallet (you must be registered and have sufficient funds in your wallet to complete your purchase).</li>
						<li>Your debit card details are required to complete the payment process: your card number is the 20-digit number on your card; your expiration date can be found underneath your card number; and finally your CVV- the three digit number on your debit card. You will get an OTP code from your bank; input the OTP on the next page to authorize the payment.</li>
						<li>Using the bank transfer option, click "pay with bank transfer" and transfer the cash into the account details shown.</li>
						<li>You may pay in USSD by selecting the USSD option and selecting your bank. Once the USSD code has been generated, copy the number and dial it on your phone to complete payment.</li>
						<li>Your wallet/account will be debited once your payment has been authorized. A notification email and SMS will be sent to you.</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>What should I do if I'm billed for a failed transaction?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>As soon as you experience this issue, make sure to contact our 24-hour support team via Live-chat, 08035001523 or info@reload.ng. In most cases, you should receive a reversal of the payment from your bank within 24 hours.</p>
					<p>PIN AND TOKEN Challenges. Please feel free to contact our 24 hour support team via Live-chat, via phone at 08035001523, via email at info@reload.ng or via social media for a swift response.</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>What caused my transaction to fail?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>There are several reasons why a transaction can fail:</p>
					<ul>
						<li>Due to a bank network related issue</li>
						<li>Unresponsive issuer, switch or service provider.</li>
						<li>OTPs that are incorrect or expired</li>
						<li>Debit cards that are inactive</li>
					</ul>
				</div>
			</div>
			{/* hhhhhhhhhhhhhh */}
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>What is the reason for the debt on my meter?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>There are several reasons why this could occur.</p>
					<ul>
						<li>The meter you have is a newly installed meter. During the first recharge of the meter, the cost of the free units is paid by the customer as (debt).</li>
						<li>If the customer was previously using an analogue meter before the installation of a prepaid meter, or with an estimated billing account (Post Paid) and had a debt before the installation of the prepaid meter, the debt on the estimated billing account (Post Paid) will be transferred to the prepaid meter account.</li>
						<li>In this case, the debt is the result of an illegal connection or bypass discovered by the distribution company.</li>
						<li>For better clarity, we recommend you contact your local electric distribution company.</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>Can I transfer a token I purchased with a wrong meter number?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>That's not possible. With the help of the meter number, a token is generated and encrypted. Thus, once a token has been generated, it can only be used by a particular meter. Transfers are not possible.</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>The number of units I received after purchase was less</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>These may be the reasons.</p>
					<ul>
						<li>There are pending arrears on your meter, so the debt has been deducted from your purchase.</li>
						<li>There has been a change in your tariff plan. Tariffs are determined by your distribution company, which has the right to change them without notice at any time.</li>
					</ul>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>As soon as I loaded my token, the response used appeared on my device, but it did not credit my meter.</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>Tokens can only be used with the meter number for which they were generated. Tokens are only valid on the meter they were generated for. In this case, used refers to the token being loaded onto the meter already.</p>
					<p>Confirm that there is power supply in your area and on the phase, you are on.</p>
					<p>A technical fault, phase loss, or tampering mode can also cause this. In this case, you should contact your distribution company.</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>Can you tell me what my smartcard/IUC number is?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>The IUC/smartcard number is the number assigned to your decoder. You will find it on a red label underneath your decoder if you are a GOTV subscriber. If you're a DSTV or StarTimes subscriber, take your smartcard out of your decoder, It has your smartcard number on it.</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>How can Reload.ng be accessed?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>Reload.ng works perfectly on all devices. The Reload.ng website can be accessed from a computer, smart phone or tablet. Simply go to Reload.ng and you'll be able to get started.</p>
					<p>The Reload.ng mobile app is available for android and iphone users in the Google Play Store and App Store.</p>
				</div>
			</div>
			<div className="question-wrapper">
				<div className='question' id={6}>
					<h3>Do I have to pay an additional fee to use Reload.ng?</h3>
					<button onClick={() => handleClick(6)}>
						<svg className={idVal === 6 ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
							<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
						</svg>
					</button>
				</div>
				<div className={idVal === 6 ? 'answer active' : 'answer'}>
					<p>Airtime and data recharges are free, but other services require a convenience fee of N99.44 unless you are a partner. For info on how to partner, visit <a href="https://www.reload.ng">Reload.ng</a></p>
				</div>
			</div>
		</>
	)
}

function Faq(props) {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [searchResults, setSearchResults] = React.useState([]);

	const handleSearchChange = e => {
		setSearchTerm(e.target.value);
	};

	// React.useEffect(() => {
	// 	const results = props.data.filter(item =>
	// 		item.question.toLowerCase().includes(searchTerm)
	// 	);
	// 	setSearchResults(results);
	// }, [searchTerm]);

	return (
		<div className='container'>
			<h2 className="heading">Frequently asked questions</h2>
			<hr />
			<Question />
		</div>
	)
}

export default Faq