import React, { useState } from "react";
import { Button, FormGroup, Spinner } from "reactstrap";
import { updateProfile } from "../../store/actions/agentAuth";
import { dataValues } from "../../utils/dataValues";
import { useDispatch } from "react-redux";


const UpdatePro = () => {
    const dispatch = useDispatch()
    const [values, setValues] = useState({})
    const [loader, setLoader] = useState(false)

    const handleChange = (e, name) => {
        const newValues = { ...values };
        newValues[name] = e.target.value;
        setValues(newValues);
    };

    const onFormSubmit = (e) => {
        e.preventDefault()

        if (values) {
            if (values["fullname"] || values["address"] || values["phone"]) {
                console.log(values)
                setLoader(true)
                dispatch(updateProfile(values, localStorage.getItem("agentId")))
            }
        }
    }

    const getValue = code => {
        if (values) {
            if (values[code]) {
                return values[code];
            } else {
                return "";
            }
        }
        return "";
    };

    return (
        <div>
            <FormGroup>
                {dataValues.updatePassword.map((allData, i) => (
                    <div key={i} className="form-control-wrap">
                        <input
                            type={allData.type}
                            id={"default-0" + i}
                            name={allData.code}
                            required={allData.required}
                            value={getValue(allData.code)}
                            onChange={e => handleChange(e, allData.code)}
                            placeholder={allData.placeHolder}
                            className="form-control-lg form-control"
                        />
                    </div>
                ))}
            </FormGroup>

            <FormGroup>
                <Button disabled={loader ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" style={{ backgroundColor: "#FFCC28", borderRadius: "1px solid #FFCC28" }}>
                    {loader ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
            </FormGroup>
        </div>
    )
}

export default UpdatePro