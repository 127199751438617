import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const FooterNewsCategories = () => {
    return (
        <Fragment>
            <h3 className="widget-title" style={{ color: "#fff" }}>Our Top products</h3>
            <div className="row" style={{ width: "100%" }}>
                <div className='col-lg-6' style={{ display: "flex", width: "100%" }}>
                    <div className="col-lg-4" style={{ marginRight: "20px" }}>
                        <ul>
                            <li><Link to="/" style={{ color: "#fff" }}>IKEDC</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>PHED</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>DSTV</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>EKEDC</Link></li>
                            {/* <li><Link to="/" style={{ color: "#fff" }}>EKEDC</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <ul>
                            <li><Link to="/" style={{ color: "#fff" }}>DSTV</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>GOTV</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>STARTIMES</Link></li>
                            <li><Link to="/" style={{ color: "#fff" }}>MTN VTU</Link></li>
                            {/* <li><Link to="/" style={{ color: "#fff" }}>Entertainment</Link></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FooterNewsCategories;
