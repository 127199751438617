import { RANKING_LOADING, RANKING_LOADED, RANKING_FAIL } from "../constants";

const initialState = {
  isLoading: false,
  rankings: [],
  error: null,
};

const rankingRed = (state = initialState, action) => {
  switch (action.type) {
    case RANKING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case RANKING_LOADED:
      return {
        ...state,
        isLoading: false,
        rankings: action.payload,
      };

    case RANKING_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        rankings: [],
      };
    default:
      return state;
  }
};

export default rankingRed;
