import {
  AUTH_ERROR,
  REGISTER_USER,
  REGISTER_FAIL,
  USER_LOADING,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  CONTACTUS_ERROR,
  CONTACTUS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD,
} from "../constants/index.js";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  forgotPassword: null,
  forgotPassword_error: null,
  isLoading: false,
  user: null,
  msg: null,
  editSuccess: false,
  isLogin: false,
  isRegister: false,
  contactus: null,
  reset: null,
  resetErr: null,
  contactus_error: null,
  error: null
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        reset: action.payload
      }
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetErr: action.payload
      }
    case REGISTER_USER:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        isLogin: false,
        isRegister: true,
        isAuthenticated: true,
        token: action.payload.token,
        forgotPassword: false,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload
      }
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPassword_error: action.payload
      }
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload,
        isLogin: true,
        isRegister: false,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload.token,
        forgotPassword: false,
      };
    case LOGIN_FAILED:
    case REGISTER_FAIL:
    case LOGOUT_USER:
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
        forgotPassword: false,
        error: action.payload
      };
    case CONTACTUS:
      return {
        ...state,
        contactus: action.payload,
      };
    case CONTACTUS_ERROR:
      return {
        ...state,
        contactus_error: action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
