import React, { useEffect, useState } from "react";
import { Alert, Button, FormGroup, Spinner } from "reactstrap";
// import PageContainer from "../../layout/page-container/PageContainer";
// import Head from "../../layout/head/Head";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { handleGoogleRegistration, loginFunc } from "../../store/action/Account/user/auth";
// import GoogleLoginButton from "./GoogleLoginButton";
// import { FormComponent } from "../components/FormComponent";
import { dataValues } from "../utils/dataValues";
import { HIDESTATUS, SHOWSTATUS } from "../store/constants";
import Icon from "../components/icon/Icon";
import { FormComponent } from "../components/FormComponent";
import GoogleLoginButton from "./GoogleLoaginButton";
import { LoginUser, RegUser, forgotPassword, handleGoogle } from "../store/actions/userAction";
import { toast } from "react-toastify";
// import { verifyInvite } from "../../store/action/Team";

const Login = ({ setOpen }) => {
	const userdata = useSelector(state => state.authUser);
	// const [loader, setLoader] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const loginError = useSelector(state => state.authUser.error);
	const forgot = useSelector(state => state.authUser.forgotPassword)
	const [loading, setLoading] = useState(false)
	const [errorVal, setErrorVal] = useState("");
	const [screen, setScreen] = useState(0);
	// const login
	const [showPassword, setShowPassword] = useState(false);
	const [values, setValues] = useState({});

	useEffect(() => {
		if (forgot) {
			setOpen(false)
			toast.success(forgot.message)
		}
	}, [forgot])

	const handleChange = (e, name) => {
		const newValues = { ...values };
		newValues[name] = e.target.value;
		setValues(newValues);
	};

	const getValue = code => {
		if (values) {
			if (values[code]) {
				return values[code];
			} else {
				return "";
			}
		}
		return "";
	};

	const onFormSubmit = e => {
		e.preventDefault();

		if (values) {
			setLoading(true);
			if (screen === 0) {
				if (values["email"] && values["password"]) {
					setLoading(true);

					// console.log(values)
					dispatch(LoginUser(values));
				} else {
					setLoading(false);
					toast.error("Please input all fields");
				}
			} else if (screen === 1) {
				if (values["fullname"] && values["email"] && values["password"]) {
					setLoading(true);
					// console.log(values)
					dispatch(RegUser(values));
				} else {
					setLoading(false);
					toast.error("Please input all fields");
				}
			} else if (screen === 2) {
				if (values["email"]) {
					setLoading(true);
					// console.log(values)
					dispatch(forgotPassword(values));
				} else {
					setLoading(false);
					toast.error("Please input all fields");
				}
			}
		} else {
			setLoading(false);
			toast.error("Please input all fields");
		}
	};

	useEffect(() => {
		if (userdata !== null) {
			setLoading(false);
			// window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
		}
	}, [userdata]);

	useEffect(() => {
		if (loginError) {
			setLoading(false);
			toast.error(loginError.data.message);
		}
	}, [loginError]);

	useEffect(() => {
		if (userdata && userdata.user) {
			setLoading(false);
			setOpen(false)
			toast.success(userdata.user.message);
		}
	}, [userdata]);

	const handleToggle = () => {
		setScreen(1)
		setValues({})
	}

	const handleToggles = () => {
		setScreen(0)
		setValues({})
	}

	const handleToggleForg = () => {
		setScreen(2)
		setValues({})
	}

	const handleToggleReset = () => {
		setScreen(3)
		// setValues({})
	}

	return (
		<React.Fragment>
			<div className="brand-logo pb-4 text-center">

				<h3>{screen === 0 ? "Sign in" : screen === 1 ? "Register" : screen === 2 ? "Forgot password" : "Reset credentials"}</h3>
			</div>

			{errorVal && (
				<div className="mb-3">
					<Alert color="danger" className="alert-icon">
						{" "}
						<Icon name="alert-circle" /> Unable to login with credentials{" "}
					</Alert>
				</div>
			)}

			<div>
				{screen === 0 ? <>
					{dataValues.loginData.map((allData, i) => (
						<FormGroup key={i}>
							<div style={{ display: "flex", justifyContent: "space-between" }} className="form-label-group">
								<label className="form-label" htmlFor="default-01">
									{allData.name}
								</label>
								<label className="form-label">{allData.code === "onames" ? "(Optional)" : ""}</label>
								{allData.name === "Password" ? (
									<span style={{ color: "#FFCC28", "cursor": "pointer" }} onClick={handleToggleForg}>Forgot Password?</span>
								) : (
									""
								)}
							</div>
							<div className="form-control-wrap">
								<input
									type={showPassword ? "text" : allData.type}
									id={"default-0" + i}
									name={allData.code}
									required={allData.code === "onames" || allData.code === "processorRef" ? false : true}
									value={getValue(allData.code)}
									onChange={e => handleChange(e, allData.code)}
									placeholder={allData.placeHolder}
									// className="form-control-lg form-control"
									className={`form-control-lg form-control ${allData.type === "password" && showPassword ? "is-hidden" : "is-shown"
										}`}
								/>
							</div>
						</FormGroup>
					))}

					<FormGroup>
						<Button disabled={loading ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" type="submit" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }}>
							{loading ? <Spinner size="sm" color="light" /> : "Login"}
						</Button>
					</FormGroup>
				</> : screen === 1 ? <>
					{dataValues.registerData.map((allData, i) => (
						<FormGroup key={i}>
							<div style={{ display: "flex", justifyContent: "space-between" }} className="form-label-group">
								<label className="form-label" htmlFor="default-01">
									{allData.name}
								</label>
							</div>
							<div className="form-control-wrap">
								<input
									type={showPassword ? "text" : allData.type}
									id={"default-0" + i}
									name={allData.code}
									required={allData.code === "onames" || allData.code === "processorRef" ? false : true}
									value={getValue(allData.code)}
									onChange={e => handleChange(e, allData.code)}
									placeholder={allData.placeHolder}
									// className="form-control-lg form-control"
									className={`form-control-lg form-control ${allData.type === "password" && showPassword ? "is-hidden" : "is-shown"
										}`}
								/>
							</div>
						</FormGroup>
					))}

					<FormGroup>
						<Button disabled={loading ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" type="submit" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }}>
							{loading ? <Spinner size="sm" color="light" /> : "Register"}
						</Button>
					</FormGroup></> : <>
					{dataValues.forgot.map((allData, i) => (
						<FormGroup key={i}>
							<div style={{ display: "flex", justifyContent: "space-between" }} className="form-label-group">
								<label className="form-label" htmlFor="default-01">
									{allData.name}
								</label>
							</div>
							<div className="form-control-wrap">
								<input
									type={showPassword ? "text" : allData.type}
									id={"default-0" + i}
									name={allData.code}
									required={allData.code === "onames" || allData.code === "processorRef" ? false : true}
									value={getValue(allData.code)}
									onChange={e => handleChange(e, allData.code)}
									placeholder={allData.placeHolder}
									// className="form-control-lg form-control"
									className={`form-control-lg form-control ${allData.type === "password" && showPassword ? "is-hidden" : "is-shown"
										}`}
								/>
							</div>
						</FormGroup>
					))}

					<FormGroup>
						<Button disabled={loading ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" type="submit" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }}>
							{loading ? <Spinner size="sm" color="light" /> : "Forgot password"}
						</Button>
					</FormGroup></>}
			</div>

			{screen === 0 ? <div className="form-note-s2 text-center pt-4" style={{ width: "100%" }}>
				New on our platform? <span style={{ color: "#FFCC28", "cursor": "pointer" }} onClick={handleToggle}>Create an account</span>
			</div> : <div className="form-note-s2 text-center pt-4" style={{ width: "100%" }}>
				Already have an account? <span style={{ color: "#FFCC28", "cursor": "pointer" }} onClick={handleToggles}>Login</span>
			</div>}
			<div className="text-center pt-4 pb-3">
				<h6 className="overline-title overline-title-sap">
					<span>OR</span>
				</h6>
			</div>
			<ul style={{ display: "flex", justifyContent: "center" }} className="nav justify-center gx-4">
				<li className="nav-item">
					<GoogleLoginButton />
				</li>
			</ul>
		</React.Fragment>
	);
};
export default Login;
