import React, { useState } from 'react';
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FooterMoreNews from "../FooterMoreNews";
import TwitterFeed from "../TwitterFeed";
import FontAwesome from "../uiStyle/FontAwesome";

import flogo from '../../doc/img/logo/footer_logo.png';
import FooterNewsCategories from "../FooterNewsCategories";

const FooterArea = ({ className }) => {
	const [email, setEmail] = useState('');
	const submitHandler = e => {
		e.preventDefault();
		setEmail('')
	};
	return (
		<div className={`footer footer_area1 ${className ? className : ''}`}>
			<div className="container">
				<div className="cta">
					<div className="row">
						<div className="col-md-6 align-self-center">
							<div className="footer_logo logo">
								<Link to="/">
									<img src="https://www.reload.ng/reloadng/static/media/logo.7291466eadcb78486d77.png" alt="logo" />
								</Link>
							</div>
							<div className='' style={{ display: "flex", width: "100%", color: "#fff" }}>
								Reload is a company that specializes in online bill payments. We're establishing an environment that will allow people to pay there bills online fast and very reliable.
							</div>

						</div>
						<div className="col-md-6 col-lg-4 offset-lg-2 align-self-center">
							<div className="social2">
								<ul className="inline">
									<li><Link to="#"><FontAwesome name="twitter" /></Link></li>
									<li><Link to="#"><FontAwesome name="facebook-f" /></Link></li>
									<li><Link to="#"><FontAwesome name="instagram" /></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="border_white" />
				<div className="space-40" />
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<div className="row">
							<div className="col-sm-9 col-lg-8">
								<div className="single_footer_nav border_white_right">
									<FooterNewsCategories />
								</div>
							</div>
							<div className="col-sm-4 col-lg-4">
								<div className="single_footer_nav">
									<h3 className="widget-title2" style={{ color: "#fff" }}>Our categories</h3>
									<div className="row">
										<div className="col-lg-6">
											<ul>
												<li><Link to="/" style={{ color: "#fff" }}>Electricity</Link>
												</li>
												<li><Link to="/" style={{ color: "#fff" }}>Cable TV</Link>
												</li>
												<li><Link to="/" style={{ color: "#fff" }}>Mobile data</Link>
												</li>
												<li><Link to="/" style={{ color: "#fff" }}>MObile Airtime</Link>
												</li>
											</ul>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<FooterCopyright />
		</div>
	);
};

export default FooterArea;