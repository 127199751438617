import axios from "axios"
import { secondTokenConfig } from "../constants/headers";
import { FUND, FUND_ERROR, USER_ID, USER_ID_ERROR, VERIFY_WALLET_FUNDING, VERIFY_WALLET_FUNDING_ERROR, WALLET_BALANCE_ERROR, WALLET_BALANCE_LOADED, WALLET_BALANCE_LOADING, WALLET_FUNDING, WALLET_FUNDING_ERROR } from "../constants";
import { getUser } from "./userAction";

export const userWallet = (billeruserId) => async (dispatch, getState) => {
	// const body = JSON.stringify(ref);
	await axios
		.get(
			`${process.env.REACT_APP_API}/api/userwallet/${billeruserId}`,
			secondTokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			console.log(res.data)
			localStorage.setItem("accountno", res.data.accountNo)
			dispatch(WalletBalance(res.data.accountNo))
			dispatch({
				type: USER_ID,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: USER_ID_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const walletfunding = (ref) => async (dispatch, getState) => {
	// const body = JSON.stringify(ref);
	await axios
		.post(
			`${process.env.REACT_APP_API}/api/wallettrans/funding/initialize`,
			ref,
			secondTokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			dispatch({
				type: WALLET_FUNDING,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: WALLET_FUNDING_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

// export const verifyFunds = (ref) => async (dispatch, getState) => {
// 	// const body = JSON.stringify(ref);
// 	await axios
// 		.post(
// 			`${process.env.REACT_APP_API}/api/wallettrans/verify`,
// 			ref,
// 			secondTokenConfig(getState),
// 			{ timeout: 200 }
// 		)
// 		.then((res) => {
// 			dispatch({
// 				type: VERIFY_WALLET_FUNDING,
// 				payload: res.data,
// 			})
// 		})
// 		.catch((err) => {
// 			dispatch({
// 				type: VERIFY_WALLET_FUNDING_ERROR,
// 				payload: "An error occurred: " + err.response,
// 			});
// 		})
// };

export const FundWallet = (values, userWalletId) => async (dispatch, getState) => {
	// const body = JSON.stringify(ref);
	await axios
		.post(
			// `${process.env.REACT_APP_API}/api/wallettrans/funding/${userWalletId}`,
			`${process.env.REACT_APP_API}/api/wallettrans/funding`,
			values,
			secondTokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			dispatch(getUser())
			dispatch({
				type: FUND,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: FUND_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const WalletBalance = (accountno) => async (dispatch, getState) => {
	dispatch({ type: WALLET_BALANCE_LOADING })
	// const body = JSON.stringify(ref);
	await axios
		.get(
			`${process.env.REACT_APP_API}/api/wallettrans/balance/${accountno}`,
			secondTokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			dispatch({
				type: WALLET_BALANCE_LOADED,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: WALLET_BALANCE_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};