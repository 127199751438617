import React, { Fragment, useEffect } from "react";
import PostCarousel from "../../components/PostCarousel";
import PostGallery from "../../components/PostGallery";
import EntertainmentNews from "../../components/EntertainmentNews";
import { hideLoader } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import FeatureNewsTwo from "../../components/FeatureNewsTwo";
import Agent from "../../components/PostGallery/Agent";
import { userWallet } from "../../store/actions/wallet";
import { txStatusQuery } from "../../store/actions/Coralpay";

const HomePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const finalPaymentData = useSelector(state => state.finalPayment)
  const user = useSelector(state => state.authUser.user)
  const invokeCoralPay = useSelector(state => state.invokeCoralpay.invoke)
  const txStatus_Query = useSelector(state => state.invokeCoralpay.txStatus_Query)
  const transSu = useSelector((state) => state.trans.paymentIntent);
  const nonUserIntentDetails = useSelector((state) => state.trans.nonUserIntent);

  // useEffect(() => {
  //   console.log(nonUserIntentDetails)
  //   console.log(transSu)
  //   console.log(invokeCoralPay)
  //   console.log(txStatus_Query)
  //   if (nonUserIntentDetails !== null || transSu !== null && invokeCoralPay !== null && txStatus_Query === null) {
  //     if (nonUserIntentDetails || transSu && txStatus_Query) {
  //       setTimeout(() => {
  //         history.push(`${process.env.PUBLIC_URL}/stat/receipt`)
  //       }, 2000);
  //     } else {
  //       let id = nonUserIntentDetails != null ? nonUserIntentDetails.transRef : transSu.transRef
  //       dispatch(txStatusQuery(id));
  //     }
  //   } else {
  //     console.log("this shouldn't show")
  //   }
  // }, [nonUserIntentDetails || transSu])

  useEffect(() => {
    if (finalPaymentData.data) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
      history.push(`${process.env.PUBLIC_URL}/receipt`)
    }
  }, [finalPaymentData.data])

  useEffect(() => {
    if (user) {
      dispatch(userWallet(user.user.id))
      history.push(`${process.env.PUBLIC_URL}/user`)
    }
  }, [user])

  return (
    <Fragment>
      <PostCarousel className="fifth_bgs" />
      <PostGallery className="fifth_bgs" />

      <div className="m-4">
        <div className="">
          <hr />
          <div className="row">
            <div className="col-12">
              {/* <div className="row">
                <div className="col-12"> */}
              <div className="heading">
                <h2 className="widget-title p-5" style={{ display: "flex", justifyContent: "center" }}>Why choose us</h2>
              </div>
              {/* </div>
              </div> */}
              {/*CAROUSEL START*/}
              <div className="entertrainment_carousel mb30">
                <div className="entertrainment_item">
                  <div className="row justify-content-center">
                    <EntertainmentNews />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Agent className="fifth_bgs" />
      </div>
      <div className="space-70" />
    </Fragment>
  );
};

export default HomePage;
