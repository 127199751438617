import React, { Fragment, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { connect, useSelector, useDispatch } from "react-redux";
import Routes from "../__Routes";
import ScrollTopButton from "../../components/ScrollTopButton";
import Preloader from "../../components/Loader";
import { showLoader, hideLoader } from "../../store/actions";
import { getProducts } from "../../store/actions/products";
import { getRanking } from "../../store/actions/ranking";
import DefaultModal from "../../components/DefaultModal";
// import "./App.css"
// import { FlutterpaymentHooks } from "../../utils/Flutterwave";
import { getUser } from "../../store/actions/userAction";
import { getAgent } from "../../store/actions/agentAuth";
import { userWallet } from "../../store/actions/wallet";
import { agentWallet } from "../../store/actions/agentWallet";

const App = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.meta.isLoading);
  const user = useSelector(state => state.authUser.user)
  const agent = useSelector(state => state.authUser.data)
  const { error, success } = props;
  if (error) toast.error(error);
  if (success) toast.success(success);

  useEffect(() => {
    dispatch(showLoader());
    dispatch(getProducts());
    dispatch(getRanking(0));
    if (localStorage.getItem("token")) {
      dispatch(getUser())
      if (localStorage.getItem("userId")) {
        dispatch(getRanking(localStorage.getItem("userId")));
        dispatch(userWallet(localStorage.getItem("userId")))
      }
    } else if (localStorage.getItem("agent_token")) {
      dispatch(getAgent())
      dispatch(getRanking(localStorage.getItem("agentId")));
      if (agent || localStorage.getItem("agentId")) {
        dispatch(agentWallet(localStorage.getItem("agentId")))
      }
    }
    setTimeout(() => {
      dispatch(hideLoader());
    }, 3000);
  }, []);
  return (
    <Fragment>
      <div className="">
        {/* {loader ? (
          <Preloader />
        ) : ( */}
        <>
          <Routes />
          <DefaultModal />
          <ToastContainer position="top-right" />
          <ScrollTopButton />
        </>
        {/* )} */}
      </div>
    </Fragment>
  );
};

const MapStateToProps = (state) => {
  return {
    error: state.meta.error,
    success: state.meta.success,
  };
};

export default connect(MapStateToProps)(App);
