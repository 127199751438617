import axios from "axios";
import { secondTokenConfig, tokenConfig } from "../constants/headers";
import { AGENT_ID, AGENT_ID_ERROR, GET_AGENT_BALANCE, GET_AGENT_BALANCE_ERROR, WALLET_AGENT_FUNDED, WALLET_AGENT_FUNDED_ERROR, WALLET_AGENT_FUNDING, WALLET_AGENT_FUNDING_ERROR } from "../constants";
import { toast } from "react-toastify";

export const agentWallet = (agentId) => async (dispatch, getState) => {
	// const body = JSON.stringify(ref);
	await axios
		.get(
			`${process.env.REACT_APP_API}/api/agentwallet/${agentId}`,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			// console.log(res.data)
			localStorage.setItem("accountno", res.data.accountNo)
			dispatch(getBalanceFunding(res.data.accountNo))
			dispatch({
				type: AGENT_ID,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: AGENT_ID_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const agentwalletfunding = (ref) => async (dispatch, getState) => {
	await axios
		.post(
			`${process.env.REACT_APP_API}/api/agentwallet/wallet/intent`,
			ref,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			toast.success("Funding initiated")
			dispatch({
				type: WALLET_AGENT_FUNDING,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: WALLET_AGENT_FUNDING_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const agentwalletfund = (ref) => async (dispatch, getState) => {
	await axios
		.post(
			`${process.env.REACT_APP_API}/api/agentwallet/funding`,
			ref,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			console.log(res.data)
			dispatch(getBalanceFunding(localStorage.getItem("accountno")))
			dispatch({
				type: WALLET_AGENT_FUNDED,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: WALLET_AGENT_FUNDED_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const getBalanceFunding = (accountno) => async (dispatch, getState) => {
	await axios
		.get(
			`${process.env.REACT_APP_API}/api/agentwallet/balance/${accountno}`,
			tokenConfig(getState)
		)
		.then((res) => {
			dispatch({
				type: GET_AGENT_BALANCE,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: GET_AGENT_BALANCE_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};