import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ChangePassword } from "../../store/actions/agentAuth";
import { Button, FormGroup, Spinner } from "reactstrap";
import { dataValues } from "../../utils/dataValues";


const ChangePwd = () => {
	const dispatch = useDispatch()
	const [values, setValues] = useState({})
	const [loader, setLoader] = useState(false)
	const [showPassword, setShowPassword] = useState(false);

	const handleChange = (e, name) => {
		const newValues = { ...values };
		newValues[name] = e.target.value;
		setValues(newValues);
	};

	const getValue = code => {
		if (values) {
			if (values[code]) {
				return values[code];
			} else {
				return "";
			}
		}
		return "";
	};

	const onFormSubmit = (e) => {
		e.preventDefault()

		if (values) {
			if (values["oldPassword"] || values["newPassword"]) {
				console.log(values)
				setLoader(true)
				dispatch(ChangePassword(values, localStorage.getItem("agentId")))
			}
		}
	}

	const showHide = (e) => {
		e.preventDefault()

		setShowPassword(!showPassword)
	}

	return (<div>
		<FormGroup>
			{dataValues.changePassword.map((allData, i) => (
				<>
					<label className="password">{allData.name}
						<input type={showPassword ? "text" : allData.type} className="password__input" onChange={e => handleChange(e, allData.code)} placeholder={allData.placeHolder} />
						<span className="password__show" onClick={showHide}>{showPassword ? 'Hide' : 'Show'}</span>
					</label>
				</>
			))}
		</FormGroup>

		<FormGroup>
			<Button disabled={loader ? true : false} onClick={onFormSubmit} size="lg" className="btn-block" style={{ backgroundColor: "#FFCC28", borderRadius: "1px solid #FFCC28" }}>
				{loader ? <Spinner size="sm" color="light" /> : "Submit"}
			</Button>
		</FormGroup>
	</div>)
}

export default ChangePwd