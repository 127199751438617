import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import FontAwesome from "../uiStyle/FontAwesome";

const data = [
  {
    id: 1,
    message: "Believe you can and you're halfway there."
  },
  {
    id: 2,
    message: "The only way to do great work is to love what you do."
  },
  {
    id: 3,
    message: "Success is not final, failure is not fatal: It is the courage to continue that counts.",
  },
  {
    id: 4,
    message: "The future belongs to those who believe in the beauty of their dreams.",
  },
  {
    id: 5,
    message: "It does not matter how slowly you go as long as you do not stop."
  }
]

const TopBar = ({ className, dark }) => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  // Get the current date
  const currentDate = new Date();

  // Define options for formatting
  const options = {
    weekday: "long", // Full weekday name (e.g., "Thursday")
    year: "numeric", // 4-digit year (e.g., "2020")
    month: "long", // Full month name (e.g., "March")
    day: "numeric", // Day of the month (e.g., "26")
  };

  // Format the date
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  return (
    <div className={`topbar ${className ? className : ""}`} id="top">
      <div className="container">
        <div className="row">
          <div className="col-md-8 align-self-center">
            <div className={`trancarousel_area ${dark ? "white" : ""}`}>
              <p className="trand">Trending</p>
              <div className="nav_style1">
                <Swiper
                  getSwiper={setSwiper}
                  className="trancarousel"
                  {...params}
                >
                  {data.map((allData, i) => (
                    <div key={i} className="trancarousel_item">
                      <p>
                        {allData.message}
                      </p>
                    </div>
                  ))}
                </Swiper>
                <div className="navBtns">
                  <button className="navBtn prevBtn" onClick={goPrev}>
                    <FontAwesome name="angle-left" />
                  </button>
                  <button className="navBtn nextBtn" onClick={goNext}>
                    <FontAwesome name="angle-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 align-self-center">
            <div className="top_date_social text-right">
              <div className={`paper_date ${dark ? "white" : ""}`}>
                <p>{formattedDate}</p>
              </div>
              <div className={`social1 ${dark ? "white" : ""}`}>
                <ul className="inline">
                  <li>
                    <Link to="#">
                      <FontAwesome name="twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesome name="facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesome name="youtube-play" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesome name="instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
