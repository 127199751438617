import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./profile.scss"
import ChangePwd from "./ChangePassword";
import UpdatePro from "./UpdatePro";

const ProfileTab = ({ className, dark }) => {
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	return (
		<div>
			<Nav tabs>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === "1" })}
						onClick={() => toggle("1")}
					>
						Change password
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === "2" })}
						onClick={() => toggle("2")}
					>
						Update account
					</NavLink>
				</NavItem>
			</Nav>

			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<ChangePwd />
				</TabPane>
				<TabPane tabId="2">
					<UpdatePro />
				</TabPane>
			</TabContent>
		</div>
	);
};

export default ProfileTab;
