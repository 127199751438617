import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import FontAwesome from "../uiStyle/FontAwesome";
import { useSelector } from "react-redux";
import "./style.css"

const PostCarousel = ({ className }) => {
  const ranking = useSelector((state) => state.ranking.rankings);
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const params = {
    slidesPerView: 3,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  };

  //   console.log(ranking);
  return (
    <div className={className ? className : ""}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="carousel_posts1 owl-carousel nav_style2 mb40 mt30">
              {/*CAROUSEL START*/}
              <div className="px-4 position-relative">
                <Swiper
                  getSwiper={setSwiper}
                  className="trancarousel"
                  style={{ flexShrink: 1 }}
                  {...params}
                >
                  {ranking.map((item, i) => (
                    <div
                      key={i}
                      className="single_post widgets_small post_type5"
                    >
                      <div className="post_img">
                        <div className="img_wrap">
                          <Link to="/">
                            <img src={item.product.logourl} alt="slider5" />
                          </Link>
                        </div>
                      </div>
                      <div className="single_post_text">
                        <h4>
                          {item.product.productname}
                          {/* <Link to="/post1">{item.title}</Link> */}
                        </h4>
                        <p>{item.product.description}</p>
                        <p>{item.percentage}%</p>
                      </div>
                    </div>
                  ))}
                </Swiper>
                <div className="owl-nav">
                  <div onClick={goPrev} className="owl-prev">
                    <FontAwesome name="angle-left" />
                  </div>
                  <div onClick={goNext} className="owl-next">
                    <FontAwesome name="angle-right" />
                  </div>
                </div>
              </div>
            </div>
            {/*CAROUSEL END*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCarousel;
