import axios from "axios";
import { AGENT_LOGERROR, AGENT_LOGIN, AGENT_REGERROR, AGENT_REGISTER, AUTH_AGENT_ERROR, FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, RESET_PASSWORD, RESET_PASSWORD_FAIL, UPDATE_PROFILE, UPDATE_PROFILE_ERROR, USER_AGENT_LOADED, USER_AGENT_LOADING } from "../constants";
import { secondTokenConfig, tokenConfig } from "../constants/headers";
import { agentWallet } from "./agentWallet";
import { toast } from "react-toastify";
// import { configJson } from "../constants/headers";

export const getAgent = () => (dispatch, getState) => {
	dispatch({ type: USER_AGENT_LOADING });
	axios
		.get(
			`${process.env.REACT_APP_API}/api/agent/auth`,
			tokenConfig(getState)
		)
		.then((res) => {
			console.log(res)
			localStorage.setItem("agentId", res.data.user.id);
			dispatch(agentWallet(res.data.user.id))
			dispatch({
				type: USER_AGENT_LOADED,
				payload: res.data,
			})
		})
		.catch((err) => {
			// console.log(err)
			dispatch({
				type: AUTH_AGENT_ERROR,
				payload: "An error occurred"
				// payload: "An error occurred: " + err.response.status,
			});
			// }
		});
};

export const agentRegister = (values) => (dispatch) => {
	const config = {
		headers: {
			merchantKey: "099035353",
			"Content-Type": "application/json",
		},
	};

	axios
		.post(
			`${process.env.REACT_APP_API}/api/agent/register`,
			values,
			config
		)
		.then((res) => {
			// dispatch(hideLoader())Loader());
			console.log(res)
			localStorage.setItem("agentId", res.data.user.id);
			dispatch(agentWallet(res.data.user.id))
			return dispatch({
				type: AGENT_REGISTER,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: AGENT_REGERROR,
				payload: "An error occurred: " + err.response.status,
			});
		});
};

export const agentLogin = (ref) => async (dispatch) => {
	const config = {
		headers: {
			merchantKey: "099035353",
			"Content-Type": "application/json",
		},
	};
	await axios
		.post(
			`${process.env.REACT_APP_API}/api/agent/login`,
			ref,
			config
		)
		.then((res) => {
			// console.log(res)
			localStorage.setItem("agentId", res.data.user.id);
			dispatch(agentWallet(res.data.user.id))
			dispatch({
				type: AGENT_LOGIN,
				payload: res.data,
			})
		})
		.catch((err) => {
			toast.error(err.response.data.message)
			// Handle other HTTP status codes as needed
			console.log(err)
			dispatch({
				type: AGENT_LOGERROR,
				payload: err.response.data.message,
			});
		});
};

export const updateProfile = (values, agentId) => async (dispatch, getState) => {
	await axios
		.put(
			`${process.env.REACT_APP_API}/api/agent/update/${agentId}`,
			values,
			secondTokenConfig(getState)
		)
		.then((res) => {
			toast.success("Successfully updated profile")
			dispatch({
				type: UPDATE_PROFILE,
				payload: res.data,
			})
		})
		.catch((err) => {
			// Handle other HTTP status codes as needed
			console.log(err)
			dispatch({
				type: UPDATE_PROFILE_ERROR,
				payload: "An error occurred: ",
			});
		});
}

export const ChangePassword = (values, agentId) => async (dispatch, getState) => {
	await axios
		.put(
			`${process.env.REACT_APP_API}/api/agent/changepassword/${agentId}`,
			values,
			secondTokenConfig(getState)
		)
		.then((res) => {
			toast.success("Successfully updated profile")
			dispatch({
				type: UPDATE_PROFILE,
				payload: res.data,
			})
		})
		.catch((err) => {
			// Handle other HTTP status codes as needed
			console.log(err)
			dispatch({
				type: UPDATE_PROFILE_ERROR,
				payload: "An error occurred: ",
			});
		});
}

export const forgotPassword = (values) => (dispatch, getState) => {
	axios.post(`${process.env.REACT_APP_API}/api/agent/mobile/forgotpassword`, values, secondTokenConfig(getState))
		.then(res => {
			console.log(res.data)
			toast.success(res.data.message)
			dispatch({
				type: FORGOT_PASSWORD,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: FORGOT_PASSWORD_FAIL,
				payload: "An error occurred: "
			})
		})
}

export const resetPassword = (user) => (dispatch, getState) => {
	axios
		.post(
			`${process.env.REACT_APP_API}/api/agent/mobile/resetpassword`,
			user,
			secondTokenConfig(getState)
		)
		.then((res) => {
			toast.success("Successfully reset password.")
			return dispatch({
				type: RESET_PASSWORD,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: RESET_PASSWORD_FAIL,
			});
			// }
		});
};