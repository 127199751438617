import React from "react";
// import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Spinner } from "reactstrap";
// import { Icon, Button } from "../Component";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";
// import PasswordChecklist from "react-password-checklist";

export const FormComponent = ({
  passwordCheck,
  data,
  loading,
  handleChange,
  handleDone,
  values,
  ButtonName,
  channel,
  productList,
  setPasswordValidity
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleFieldChange = (event, code) => {
    handleChange(event, code);
  };

  const getValue = code => {
    if (values) {
      if (values[code]) {
        return values[code];
      } else {
        return "";
      }
    }
    return "";
  };

  return (
    <>
      <div className="w-full">
        <Form className="is-alter" onSubmit={handleDone}>
          {data.map((allData, i) => (
            <FormGroup key={i}>
              <div style={{display: "flex", justifyContent:"space-between"}}  className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  {allData.name}
                </label>
                <label className="form-label">{allData.code === "onames" ? "(Optional)" : ""}</label>
                {ButtonName === "Sign in" && allData.name === "Password" ? (
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/forgot-password`}>
                    Forgot Password?
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="form-control-wrap">
                {/* {allData.type === "password" ? (
                  <a
                    href="#password"
                    onClick={ev => {
                      ev.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${showPassword ? "is-hidden" : "is-shown"
                      }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                ) : (
                  ""
                )} */}
                <input
                  type={showPassword ? "text" : allData.type}
                  id={"default-0" + i}
                  name={allData.code}
                  required={allData.code === "onames" || allData.code === "processorRef" ? false : true}
                  value={getValue(allData.code)}
                  onChange={e => handleFieldChange(e, allData.code)}
                  placeholder={allData.placeHolder}
                  className="form-control-lg form-control"
                // className={`form-control-lg form-control ${allData.type === "password" && showPassword ? "is-hidden" : "is-shown"
                //   }`}
                />
              </div>
            </FormGroup>
          ))}

          <FormGroup>
            <Button disabled={loading ? true : false} onSubmit={handleDone} size="lg" className="btn-block" type="submit" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }}>
              {loading ? <Spinner size="sm" color="light" /> : ButtonName}
            </Button>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};
