import { DEFAULT, IS_LOADING } from "../constants";

export const defaultAction = () => (dispatch) => {
  dispatch({
    type: DEFAULT,
  });
};

export const showLoader = () => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: true });
};

export const hideLoader = () => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: false });
};
