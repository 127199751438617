import axios from "axios";
import { INVOKE_CORALPAY, INVOKE_CORALPAY_ERROR, TXSTATUS_QUERY, TXSTATUS_QUERY_ERROR, TxQUERY_CORALPAY, TxQUERY_CORALPAY_ERROR } from "../constants";
import { tokenConfig } from "../constants/headers";

export const invokeCoralPay = (values) => (dispatch, getState) => {
	axios
		.post(
			`${process.env.REACT_APP_API}/api/coralpay/cgate/invoke`,
			values,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			dispatch({
				type: INVOKE_CORALPAY,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: INVOKE_CORALPAY_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const txQuery = (values) => (dispatch, getState) => {
	axios
		.post(
			`${process.env.REACT_APP_API}/api/coralpay/cgate/callback/txQuery`,
			values,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			dispatch({
				type: TxQUERY_CORALPAY,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: TxQUERY_CORALPAY_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};

export const txStatusQuery = (id) => (dispatch, getState) => {
	axios
		.get(
			`${process.env.REACT_APP_API}/api/transaction/payment/tx/status/${id}`,
			tokenConfig(getState),
			{ timeout: 200 }
		)
		.then((res) => {
			// localStorage.setItem("stat", true)
			dispatch({
				type: TXSTATUS_QUERY,
				payload: res.data,
			})
		})
		.catch((err) => {
			dispatch({
				type: TXSTATUS_QUERY_ERROR,
				payload: "An error occurred: " + err.response,
			});
		})
};