import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { finalPayment } from "../store/actions/finalPayment";
import { hideLoader, showLoader } from "../store/actions"

export const FlutterpaymentHooks = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intentData = useSelector((state) => state.trans.paymentIntent);
  const modalSet = useSelector((state) => state.modalSet);
  const [amount, setAmount] = useState(null);
  const [email, setEmail] = useState(null);
  const [transRef, setTransRef] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [state, setState] = useState(false);


  const handleFlutterPayment = useFlutterwave({
    public_key: `${process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY}`,
    tx_ref: intentData ? intentData.transRef : transRef,
    amount: intentData ? intentData.result.totalAmount : amount,
    currency: "NGN",
    payment_options: "card",
    trackingNo: intentData ? intentData.transRef : transRef,
    customer: {
      email: modalSet ? modalSet.submit.email : email,
      name: modalSet ? modalSet.submit.accountName : accountName,
    },
    customizations: {
      title: "Reload.ng",
      description: "Make all Bill Payments",
      logo:
        "https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/Reload.jpeg",
    },
  });

  useEffect(() => {
    if (state) {
      // console.log("working");
      handleFlutterPayment({
        callback: (response) => {
          const ref = {
            // transRef: "bp02312070004623",
            transRef: intentData ? intentData.transRef : transRef,
            paymentRef: response.transaction_id,
            accountNumber: modalSet.submit.accountNumber ? modalSet.submit.accountNumber : modalSet.submit.phoneNumber,
          };

          console.log(ref);

          dispatch(finalPayment(ref));
          dispatch(showLoader());
          setTimeout(() => {
            closePaymentModal();
          }, 2000);
        },
        onClose: () => {
          closePaymentModal();
        },
      });
    }
  }, [state]);

  return {
    setState,
    setAccountName,
    setAmount,
    setTransRef,
    setEmail,
  };
};
