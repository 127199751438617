import React, { useEffect, useState } from 'react'
import "./paymentFailed.css"
import { Button, Spinner } from 'reactstrap'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { requeryTxn } from '../../store/actions/finalPayment'
import failed from "../../images/images/failed.jpg"

export default function PaymentFailed() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loader, setLoader] = useState(false)
	const ref = useSelector(state => state.trans.paymentIntent)
	const intent = useSelector((state) => state.trans);
	const [message, setMessage] = useState(null)
	const requError = useSelector(state => state.finalPayment.requeryError)
	const intentData = useSelector((state) => state.trans.nonUserIntent);
	const requ = useSelector(state => state.finalPayment.requery)
	const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query)

	const handleClick = (e) => {
		e.preventDefault()

		// if (ref && ref.transRef !== null) {
		setLoader(true)
		let reference = intentData ? intentData.transRef : intent.paymentIntent ? intent.paymentIntent.transRef : intent.walletUserInit ? intent.walletUserInit.transRef : null

		if (reference) {
			dispatch(requeryTxn(reference))
		} else {
			window.location.href = `${process.env.PUBLIC_URL}/`
		}
	}

	useEffect(() => {
		if (requ && !tranStatus_query) {
			setTimeout(() => {
				setLoader(false)
				// dispatch(hideLoader());
			}, 3000);
			history.push(`${process.env.PUBLIC_URL}/receipt`)
		}

		if (requ && tranStatus_query) {
			setTimeout(() => {
				setLoader(false)
				// dispatch(hideLoader());
			}, 3000);
			history.push(`${process.env.PUBLIC_URL}/stat/receipt`)
		}
	}, [requ && !tranStatus_query])

	useEffect(() => {
		if (requError) {
			setTimeout(() => {
				setLoader(false)
				setMessage("If failure still occurs please contact our support lines ")
				// dispatch(hideLoader());
			}, 3000);
		}
	}, [requError])

	return (
		<div>
			<div className='c'>
				<img src={failed} alt='failed' />
				<br />
				{/* <div className='_404'>400</div> */}
				<div className='_1'>Payment Failed</div>
				<Button className="mr-2" onClick={e => { e.preventDefault(); history.goBack() }}>Go back</Button>
				<Button disabled={loader ? true : false} onClick={handleClick} type="submit">
					{loader ? <Spinner size="sm" color="light" /> : "Requery transaction"}
				</Button>
				<h5>{message ? message : ""}</h5>
			</div>
		</div>
	)
}
