import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import secure from "../../images/images/secure.png"
import delivery from "../../images/images/delivery.jpg"
import support from "../../images/images/support.jpg"

const EntertainmentNews = () => {
  return (
    <Fragment>
      {/* {entertainments.map((item, i) => ( */}
      <div className="col-lg-3 mr-4" style={{ background: "#fff", borderRadius: "20px", maxWidth: "100%" }}>
        <div className="single_post post_type3 mb30">
          <div className="post_img">
            <div className="img_wrap">
              <img src={support} alt="thumb" width={100} height={100} />
            </div>
          </div>
          <div className="single_post_text">
            <div className="meta3">
              <b>Excellent Customer Support</b>
            </div>
            <div className="space-10" />
            <p className="post-p">
              Our well trained customer support agents are always available 24/7 to help you resolve any issues. We provide you with multiple ways to reach us and get fast help.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mr-4" style={{ background: "#fff", borderRadius: "20px" }}>
        <div className="single_post post_type  mb30">
          <div className="post_img">
            <div className="img_wrap">
              <img src={delivery} alt="thumb" width={120} height={100} />
            </div>
          </div>
          <div className="single_post_text">
            <div className="meta3">
              <b>Fast Service Delivery</b>
            </div>
            <div className="space-10" />
            <p className="post-p">
              Enjoy prompt delivery of services purchased through Reloadng. Our promise to you is to deliver value for every transaction made on-time, every time.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mr-4" style={{ background: "#fff", borderRadius: "20px" }}>
        <div className="single_post post_type3 mb30">
          <div className="post_img">
            <div className="img_wrap">
              <img src={secure} alt="thumb" width={100} />
            </div>
          </div>
          <div className="single_post_text">
            <div className="meta3">
              <b>Safe, Secured Payment</b>
            </div>
            <div className="space-10" />
            <p className="post-p">
              Payment on Reloadng is fast and 100% secured. Enjoy seamless payment processes with zero glitches. Pay with wallet, bank transfer or card.
            </p>
          </div>
        </div>
      </div>
      {/* ))} */}
    </Fragment>
  );
};

export default EntertainmentNews;
