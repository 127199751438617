import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WidgetTab from "../WidgetTab";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import { useHistory } from "react-router-dom"
// images
import sliderImg1 from "../../doc/img/header/sider-top.jpg";
import agent from "../../images/images/agent.png"
import "./style.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import Icon from "../icon/Icon";
import { submitModal } from "../../store/actions/modalSetup";
import AgentLogin from "./AgentLogin";
import AgentRegister from "./AgentRegister";
import { agentLogin, agentRegister, forgotPassword } from "../../store/actions/agentAuth";
import { toast } from "react-toastify";
import { FormComponent } from "../FormComponent";
import { dataValues } from "../../utils/dataValues";

const Agent = (props) => {
	const dispatch = useDispatch();
	const history = useHistory()
	const { className } = props;
	const selectValue = useSelector((state) => state.trans.productData);
	const modalSet = useSelector((state) => state.modalSet);
	const agentUser = useSelector(state => state.agentUser)
	const productType = useSelector(state => state.product.selectPro)
	const authUser = useSelector(state => state.authUser.user)
	const [loader, setLoader] = useState(false)
	const [login, setLogin] = useState(false)
	const [values, setValues] = useState({})
	const [forgotPass, setForgotPass] = useState(false)
	const [register, setRegister] = useState(false)

	const handleChange = (e, name) => {
		localStorage.setItem(name == "email" ? "email" : "", e.target.value)
		localStorage.setItem(name == "phoneNumber" ? "phoneNumber" : "", e.target.value)
		console.log(e.target.value)
		dispatch(submitModal(name, e.target.value));
		if (productType && productType.productId.description === "Cable") {
			selectValue.filter(allData => {
				if (allData.slug === e.target.value) {
					// console.log(allData)
					dispatch(submitModal("amount", allData.amount));
				}
			})
		}
	};

	const handleForgotChange = (e, name) => {
		const newValues = { ...values }
		newValues[name] = e.target.value
		setValues(newValues)
	}

	const getValue = (code) => {
		if (modalSet.submit) {
			if (modalSet.submit[code]) {
				// if
				// console.log("this is the code: " + code)
				return modalSet.submit[code];
			} else {
				return "";
			}
		}
		return "";
	};

	const handleLogin = (e) => {
		e.preventDefault()

		setLogin(true)
	}

	const handleRegister = (e) => {
		e.preventDefault()
		setRegister(true)
	}

	const submitModalLogin = (e) => {
		e.preventDefault()

		if (!authUser) {
			dispatch(agentLogin(modalSet.submit))
		} else {
			toast.error("Log out to continue")
		}
	}

	const submitModalRegister = (e) => {
		e.preventDefault()

		if (!authUser) {
			dispatch(agentRegister(modalSet.submit))
		} else {
			toast.error("Log out to continue")
		}
	}

	// useEffect(() => {
	// 	if (agentUser.loginData) {
	// 		setLogin(false)
	// 		history.push(`${process.env.PUBLIC_URL}/agent`)
	// 	}
	// }, [agentUser.loginData])

	useEffect(() => {
		if (agentUser.data) {
			setRegister(false)
			history.push(`${process.env.PUBLIC_URL}/agent`)
		}
	}, [agentUser.data])

	useEffect(() => {
		if (agentUser.loginData) {
			setLogin(false)
			history.push(`${process.env.PUBLIC_URL}/agent`)
		}
	}, [agentUser.loginData])

	const onFormSubmit = (e) => {
		e.preventDefault()

		console.log(values)
		dispatch(forgotPassword(values))
	}

	useEffect(() => {
		if (agentUser.forgotPass) {
			setLoader(false)
			setForgotPass(false)
		}
	}, [agentUser.forgotPass])

	return (
		<div className={`post_gallary_area mb40 ${className}`}>
			<hr />
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div style={{ paddingTop: "30px", paddingBottom: "30px", textAlign: "center" }}>
							<h2>Become an Agent</h2>
						</div>
						<div className="row">
							<div className="col-xl-8">
								<div className="slider_demo2">
									<div className="single_post post_type6 xs-mb30">
										<div className="post_img gradient1">
											<img style={{ height: "500px", width: "200%" }} src={agent} alt="thumb" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4">
								Become a part of our exceptional entrepreneur network by collaborating with reload.ng. Integrate the convenient 'easy-payments' experience into your network and earn commissions for each transaction you facilitate for your clients.
								<div style={{ marginTop: "80px", display: "flex", justifyContent: "left" }}>
									<Button onClick={handleLogin} style={{ marginRight: "20px", backgroundColor: "#FFCC28", color: "#fff" }}>
										Login
									</Button>
									<Button onClick={handleRegister} style={{ backgroundColor: "#FFCC28", color: "#fff" }}>Register</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<AgentLogin loader={loader} setLoader={setLoader} setForgotPass={setForgotPass} forgotPass={forgotPass} login={login} setLogin={setLogin} handleChange={handleChange} submitModalFunc={submitModalLogin} getValue={getValue} />
			<AgentRegister loader={loader} setLoader={setLoader} register={register} setRegister={setRegister} handleChange={handleChange} submitModalFunc={submitModalRegister} getValue={getValue} />

			<Modal isOpen={forgotPass} toggle={() => setForgotPass(false)}>
				<ModalBody>
					<a
						href="#cancel"
						onClick={(ev) => {
							ev.preventDefault();
							setForgotPass(false);
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<h4>Forgot Password</h4>
						<FormComponent
							data={dataValues.forgot}
							values={values} // Use optional chaining here
							handleChange={handleForgotChange}
							loading={loader}
							handleDone={onFormSubmit}
							ButtonName={"Submit"}
						/>

					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

const mapStateToProps = (state) => ({
	productImg: state.productImg.data,
});

export default connect(mapStateToProps, null)(Agent);
