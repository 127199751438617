import React, { Component } from "react";
import WidgetTab from "../WidgetTab";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import FontAwesome from "../uiStyle/FontAwesome";
import banner from "../../images/images/4.jpg"
import "./style.scss";
import { connect } from "react-redux";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`${className} slider_arrow arrow_right slick-arrow`}
    >
      <FontAwesome name="angle-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`${className} slider_arrow arrow_left slick-arrow`}
    >
      <FontAwesome name="angle-left" />
    </div>
  );
}

class PostGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      vModal: false,
      videoId: "0r6C3z3TEKw",
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  modalHandler = (value) => {
    this.setState({
      vModal: value,
    });
  };

  render() {
    const { className } = this.props;
    const { nav1 } = this.state;

    const navSettings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToShow: 8,
      swipeToSlide: true,
      focusOnSelect: true,
      centerMode: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };

    // console.log(this.props);

    return (
      <div className={`post_gallary_area mb40 ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xl-8">
                  <div className="slider_demo2">
                    <div className="post_img gradient1">
                      <img src={banner} alt="thumb" style={{width: "100vh"}} />
                    </div>
                  </div>
                  <div className="slider_demo1">
                    <Slider
                      ref={(slider) => (this.slider2 = slider)}
                      asNavFor={nav1}
                      {...navSettings}
                    >
                      {this.props.productImg.map((item, i) => (
                        <div key={i} className="single_gallary_item">
                          <img src={item} alt="thumb" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="col-xl-4">
                  <WidgetTab dark={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productImg: state.productImg.data,
});

export default connect(mapStateToProps, null)(PostGallery);
