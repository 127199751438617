import { INVOKE_CORALPAY, INVOKE_CORALPAY_ERROR, TXSTATUS_QUERY, TXSTATUS_QUERY_ERROR, TX_REQUERY, TX_REQUERYERR, TxQUERY_CORALPAY, TxQUERY_CORALPAY_ERROR } from "../constants";

const initialState = {
    invoke: null,
    invoke_error: null,
    txQuery: null,
    txQueryError: null,
    txStatus_Query: null,
    txStatus_QueryErr: null,
    txrequery: null,
    txrequery_err: null
};

const invokeCoralpay = (state = initialState, action) => {
    switch (action.type) {
        case INVOKE_CORALPAY:
            return {
                ...state,
                invoke: action.payload,
            };
        case INVOKE_CORALPAY_ERROR:
            return {
                ...state,
                invoke_error: action.payload
            }
        case TxQUERY_CORALPAY:
            return {
                ...state,
                txQuery: action.payload
            }
        case TxQUERY_CORALPAY_ERROR:
            return {
                ...state,
                txQueryError: action.payload
            }
        case TXSTATUS_QUERY:
            return {
                ...state,
                txStatus_Query: action.payload
            }
        case TXSTATUS_QUERY_ERROR:
            return {
                ...state,
                txStatus_QueryErr: action.payload
            }
        case TX_REQUERY:
            return {
                ...state,
                txrequery: action.payload
            }
        case TX_REQUERYERR:
            return {
                ...state,
                txrequery_err: action.payload
            }
        default:
            return state;
    }
};

export default invokeCoralpay;
