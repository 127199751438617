import { FINAL_PAYMENT, FINAL_PAYMENT_ERROR, REQUERY, REQUERY_ERROR } from "../constants";

const initialState = {
  data: null,
  err: null,
  requery: null,
  requeryError: null
};

const finalPayment = (state = initialState, action) => {
  switch (action.type) {
    case FINAL_PAYMENT:
      return {
        ...state,
        data: action.payload,
      };
    case FINAL_PAYMENT_ERROR:
      return {
        ...state,
        err: action.payload,
      };
    case REQUERY:
      return {
        ...state,
        requery: action.payload
      }
    case REQUERY_ERROR:
      return {
        ...state,
        requeryError: action.payload
      }
    default:
      return state;
  }
};

export default finalPayment;
