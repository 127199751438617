import { FUND, FUND_ERROR, USER_ID, USER_ID_ERROR, VERIFY_WALLET_FUNDING, VERIFY_WALLET_FUNDING_ERROR, WALLET_BALANCE_ERROR, WALLET_BALANCE_LOADED, WALLET_BALANCE_LOADING, WALLET_FUNDING, WALLET_FUNDING_ERROR } from "../constants";
import { toast } from "react-toastify";

const init = {
	loading: false,
	loaded: null,
	error: null,
	success: null,
	verifyfunding: null,
	verifyfund_Error: null,
	user: null,
	userError: null,
	fund: null,
	fund_error: null,
};

const walletReducer = (state = init, action) => {
	switch (action.type) {
		case WALLET_BALANCE_LOADING:
			return {
				...state,
				loading: true
			}
		case WALLET_BALANCE_LOADED:
			return {
				...state,
				loaded: action.payload
			}
		case WALLET_BALANCE_ERROR:
		case WALLET_FUNDING_ERROR:
			return {
				...state,
				error: action.payload
			}
		case WALLET_FUNDING:
			return {
				...state,
				success: action.payload
			}
		case VERIFY_WALLET_FUNDING:
			return {
				...state,
				verifyfunding: action.payload
			}
		case VERIFY_WALLET_FUNDING_ERROR:
			return {
				...state,
				verifyfund_Error: action.payload
			}
		case USER_ID:
			return {
				...state,
				user: action.payload
			}
		case USER_ID_ERROR:
			return {
				...state,
				userError: action.payload
			}
		case FUND:
			return {
				...state,
				fund: action.payload
			}
		case FUND_ERROR:
			return {
				...state,
				fund_error: action.payload
			}
		default:
			return state;
	}
};

export default walletReducer;