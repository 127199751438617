import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Button } from "reactstrap";
// import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { handleGoogle } from "../../store/action/Account/user/auth";
import google from "../images/social/google.png";
import { handleGoogle } from "../store/actions/userAction";
// import { GoogleLogin } from "react-google-login";

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
//   const userdata = useSelector(state => state.user.data);

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      setTimeout(() => {
        const data = {
          access_token: codeResponse.access_token
        };
        dispatch(handleGoogle(data));
      }, 2000);
    },
    onError: error => console.log("Login Failed:", error)
  });

//   useEffect(() => {
//     if (userdata !== null) {
//       window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
//     }
//   }, [userdata]);

  return (
    <Button style={{ background: "#fff", color: "#000" }} onClick={login}>
      <img src={google} width={20} alt="..." /> Login with Google
    </Button>
  );
};

export default GoogleLoginButton;
