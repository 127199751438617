import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, FormGroup, Spinner } from 'reactstrap'
import { dataValues } from '../../utils/dataValues';
import { resetPassword } from '../../store/actions/userAction';
import { toast } from 'react-toastify';
// import { unSubscribers } from '../store/actions/userAction';

export default function ResetPassword(props) {
    const dispatch = useDispatch()
    const [values, setValues] = useState({})
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.authUser)
    const [uid, setUID] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const productKey = params.get("token");

        setUID(productKey);
    }, []);

    const handleChange = (e, name) => {
        const newValues = { ...values };
        newValues[name] = e.target.value;
        setValues(newValues);
    };

    const getValue = code => {
        if (values) {
            if (values[code]) {
                return values[code];
            } else {
                return "";
            }
        }
        return "";
    };

    const handleDone = (e) => {
        e.preventDefault()

        setLoading(true);
        if (values["r-password"] && values["r-cmPassword"]) {

            const data = {
                password: values["r-password"],
                uid: uid,
            };

            dispatch(resetPassword(data));
        } else {
            setLoading(false);
            toast.error("Verify Passwords are the same")
            // const dataValues = { message: "Verify Passwords are the same", statusCode: 400, success: false };
            // dispatch({ type: SHOWSTATUS, payload: dataValues });
        }
    }

    useEffect(() => {
        if (user.reset) {
            setLoading(false)
            window.location.href = `${process.env.PUBLIC_URL}/`
        }
    }, [user.reset])

    useEffect(() => {
        if (user.resetErr) {
            setLoading(false)
            toast.error(user.resetErr.data.message)
        }
    }, [user.resetErr])

    return (
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ padding: "10px" }}>
                    <h3 style={{ textAlign: "center" }}>Reset password</h3>
                    <br />
                    <br />
                    {dataValues.reset.map((allData, i) => (
                        <FormGroup key={i}>
                            <div style={{ display: "flex", justifyContent: "space-between" }} className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    {allData.name}
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <input
                                    type={allData.type}
                                    id={"default-0" + i}
                                    name={allData.code}
                                    required={allData.code === "onames" || allData.code === "processorRef" ? false : true}
                                    value={getValue(allData.code)}
                                    onChange={e => handleChange(e, allData.code)}
                                    placeholder={allData.placeHolder}
                                    className="form-control-lg form-control"
                                />
                            </div>
                        </FormGroup>
                    ))}
                    <FormGroup>
                        <Button
                            onClick={handleDone}
                            size="lg"
                            style={{ width: "", backgroundColor: "#FBB211" }}
                            className="btn-block"
                            type="submit"
                            color="primary"
                        >
                            {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                        </Button>
                    </FormGroup>
                    {/* </Form> */}
                </Card>
            </div>
        </div>
    )
}
