// import React, { useEffect } from 'react'
// import { agentTransaction } from '../../store/actions/transaction'
// import { useDispatch, useSelector } from 'react-redux'
// import { Card } from 'reactstrap';
// import "../../components/PostGallery/style.scss"

// export default function AgentHome() {
//   const dispatch = useDispatch();
//   const agentTxn = useSelector(state => state.trans)

//   useEffect(() => {
//     dispatch(agentTransaction(localStorage.getItem("agentId")))
//   }, [])
//   return (
//     <div className='container'>
//       <div className='cardTxn'>
//         <table className="table">
//           <thead>
//             <tr>
//               <th>Email</th>
//               <th>Description</th>
//               <th>System reference</th>
//               <th>Status</th>
//               <th>Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {agentTxn && agentTxn.agentTxn.map((allData, i) => (
//               <tr>
//                 <td>{allData.email}</td>
//                 <td>{allData.description}</td>
//                 <th>{allData.reference}</th>
//                 <th>{allData.status == 0 ? "Failed" : "Successful"}</th>
//                 <th>{allData.amount}</th>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   )
// }


import React, { useEffect, useState } from 'react';
import { agentTransaction } from '../../store/actions/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import Pagination from 'react-js-pagination';
import '../../components/PostGallery/style.scss';
import PaginationComponent from '../../components/Pagination';

export default function AgentHome() {
  const dispatch = useDispatch();
  const agentTxn = useSelector((state) => state.trans);

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  useEffect(() => {
    dispatch(agentTransaction(localStorage.getItem('agentId')));
  }, []);

  // Calculate the total number of pages
  const totalItems = agentTxn.agentTxn ? agentTxn.agentTxn.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the range of items to display on the current page
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber)
    setActivePage(pageNumber);
  };

  return (
    <div className='container'>
      <div className='cardTxn'>
        <table className='table'>
          <thead>
            <tr>
              <th>s/no</th>
              <th>Email</th>
              <th>Description</th>
              <th>System reference</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {agentTxn &&
              agentTxn.agentTxn
                .slice(startIndex, endIndex)
                .map((allData, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{allData.email}</td>
                    <td>{allData.description}</td>
                    <td>{allData.reference}</td>
                    <td>{allData.status === 1 ? 'Successful' : allData.status === -1 ? "Failed" : "Initialized"}</td>
                    <td>{allData.amount}</td>
                  </tr>
                ))}
          </tbody>
        </table>
        <PaginationComponent
          noDown
          itemPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={handlePageChange}
          currentPage={activePage}
        />
        {/* <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          // itemClass='page-item'
          // linkClass='page-link'
        /> */}
      </div>
    </div>
  );
}
