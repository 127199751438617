export const tokenConfig = (getState) => {
  // Get token from localstorage
  const token = getState().authUser.token ? getState().authUser.token : getState().agentUser.token;

  // set Header
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // if token, add to header
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  console.log(config)

  return config;
};

export const secondTokenConfig = (getState) => {
  // Get token from localstorage
  const token = getState().authUser.token;

  // set Header
  const config = {
    headers: {
      merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  // if token, add to header
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const configJson = () => {
  // set Header
  const config = {
    headers: {
      merchantKey: "099035353",
      "access-control-allow-origin": "*",
      "Content-type": "application/json"
    }
  };

  return config;
};