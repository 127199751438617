import { combineReducers } from "redux";
import metaReducer from "./metaReducer";
import products from "./productsReducer";
import rankingRed from "./ranking";
import productImg from "./productImg";
import modalSetRed from "./modalSetRed";
import userReducer from "./userReducer";
import TransRed from "./tranRed";
import walletReducer from "./wallet";
import finalPayment from "./finalPayRed";
import agentUser from "./agentReducer";
import agentFunding from "./agentWallet";
import invokeCoralpay from "./coralReducer";

const rootReducer = combineReducers({
  authUser: userReducer,
  agentUser: agentUser,
  meta: metaReducer,
  product: products,
  ranking: rankingRed,
  productImg: productImg,
  trans: TransRed,
  modalSet: modalSetRed,
  wallet: walletReducer,
  agentFunding: agentFunding,
  finalPayment: finalPayment,
  invokeCoralpay: invokeCoralpay
});
export default rootReducer;
