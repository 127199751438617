import React, { useEffect, useRef, useState } from "react";
import "./receipt.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import html2pdf from "html2pdf.js";
import logo from "../../images/social/logo.png"
import { hideAgentIntentModal } from "../../store/actions/modalSetup";
import { txStatusQuery } from "../../store/actions/Coralpay";
import { requeryCoralTxn } from "../../store/actions/finalPayment";

export default function CoralReceipt() {
  const dispatch = useDispatch()
  const pdfRef = useRef();
  const history = useHistory();
  const finalData = useSelector((state) => state.finalPayment);
  const modalSet = useSelector(state => state.modalSet)
  const requeryData = useSelector((state) => state.finalPayment.requery);
  const intentData = useSelector((state) => state.trans.nonUserIntent);
  const intent = useSelector((state) => state.trans);
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState(null);
  const [packageName, setPackageName] = useState(null);
  const [paymentRef, setpaymentRef] = useState(null);
  const [productLogo, setProductLogo] = useState(null)
  const [amount, setAmount] = useState(null)
  const [narration, setNarration] = useState(null);
  const [status, setStatus] = useState(null);
  const [fee, setFee] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)
  const [typeData, setTypeData] = useState(null)
  const [ktc1, setktc1] = useState(null)
  const [blocking, setBlocking] = useState(false);
  const [ktc2, setktc2] = useState(null)
  const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query)
  const tranStatus_requery = useSelector(state => state.invokeCoralpay.txrequery)


  const printToPdf = () => {
    // let src = pdfRef.current.
    const pdfOptions = {
      margin: 10,
      filename: "invoice.pdf",
      image: { type: "png", quality: 0.98, src: "https://www.reload.ng/reloadng/static/media/logo.7291466eadcb78486d77.png" },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(pdfRef.current, pdfOptions);
  };

  useEffect(() => {
    dispatch(requeryCoralTxn(localStorage.getItem("ref")))
  }, [])

  useEffect(() => {
    if (tranStatus_query) {
      localStorage.setItem("ref", tranStatus_query.data.reference)
    } else {
      dispatch(requeryCoralTxn(localStorage.getItem("ref")))
      dispatch(txStatusQuery(localStorage.getItem("ref")));
    }
  }, [tranStatus_query])

  const handleBack = (e) => {
    e.preventDefault();
    localStorage.removeItem("ref")
    window.location.href = `${process.env.PUBLIC_URL}/`
    // history.goBack();
  };

  function formatAmount(amount) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  }

  useEffect(() => {
    if (finalData.data || requeryData) {
      const handleBeforeUnload = () => {
        setBlocking(true);
        // Optionally return a message to be displayed in the confirmation dialog
        return "Are you sure you want to leave?";
      };

      // Attach the event handler when the component mounts
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean up the event handler when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [finalData.data || requeryData]);

  return (
    <div>
      <div className="container" style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleBack} style={{ backgroundColor: "#FFCC28", color: "#000" }}>
          Go back
        </Button>
        <Button onClick={printToPdf}>Print to PDF</Button>
      </div>
      <section className="wrapper-invoice">
        <div className="invoice" ref={pdfRef}>
          <div className="invoice-information">
            <p>
              <b>Invoice #</b> : {tranStatus_query ? tranStatus_query.data.reference : ""}
            </p>
            <p>
              <b>Transaction Date:</b> {tranStatus_query ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "22"}
              {/* <b>Transaction Date </b>: {date ? new Date(date) : ""} */}
            </p>
          </div>
          {/* <!-- logo brand invoice --> */}
          <div className="invoice-logo-brand">
            {/* <!-- <h2>Tampsh.</h2> --> */}
            <img src={tranStatus_query ? tranStatus_query.data.productId.logourl : ""} width={100} alt="" />
          </div>
          {/* <!-- invoice head --> */}
          <div className="invoice-head">
            <div className="head client-info">
            </div>
            <div className="head client-data">
              <p>-</p>
              <p>email: {tranStatus_query ? tranStatus_query.data.email : ""}</p>
              <p>narration: {tranStatus_requery ? tranStatus_requery.responseData.customerMessage.replace("C'Gate", "") : ""}</p>
              <p>status: {tranStatus_query ? tranStatus_query.data.clientpaymentId.responseMessage : ""}</p>
              {tranStatus_requery ? tranStatus_requery.responseData.tokenData ?
                <>
                  <p>kct1: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.ktc1 : "" : ""}</p>
                  <p>kct2: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.ktc2 : "" : ""}</p>
                </>
                : "" : ""}</div>
            {/* <div>
              <p>{tranStatus_requery ? tranStatus_requery.responseData.customerMessage.replace("C'Gate", "") : ""}</p>
            </div> */}
          </div>
          {/* <!-- invoice body--> */}
          <div className="invoice-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{tranStatus_requery ? tranStatus_requery.responseData.billerName : ""}</td>
                  <td>{tranStatus_query ? tranStatus_query.data.amount : ""}</td>
                </tr>
                <tr>
                  <td>Convenience fee</td>
                  <td>{tranStatus_query ? tranStatus_query.data.fee : ""}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex-table">
              <div className="flex-column"></div>
              <div className="flex-column">
                <table className="table-subtotal">
                  <tbody>
                    <tr>
                      <td>Amount paid</td>
                      <td>{tranStatus_query ? tranStatus_query.data.amount : ""}</td>
                    </tr>
                    <tr>
                      <td>Convenience fee</td>
                      <td>{tranStatus_query ? tranStatus_query.data.fee : ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- invoice total  --> */}
            <div className="invoice-total-amount">
              <p>Total :{tranStatus_query ? tranStatus_query.data.amount : ""}</p>
            </div>
          </div>
          {/* <!-- invoice footer --> */}
          <div className="invoice-footer">
            <p>❤Thanks for your patronage</p>
          </div>
        </div>
      </section >
    </div >
  );
}
