import React from "react";
import Icon from "../icon/Icon";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
	// console.log(itemPerPage, totalItems, currentPage);
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
		pageNumbers.push(i);
	}

	const nextPage = () => {
		paginate(currentPage + 1);
	};

	const prevPage = () => {
		paginate(currentPage - 1);
	};

	return (
		<Pagination aria-label="Page navigation example">
			<PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
				<PaginationLink
					className="page-link-prev"
					onClick={(ev) => {
						ev.preventDefault();
						prevPage();
					}}
					href="#prev"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
					{/* <Icon name="chevrons-left" /> */}
					<span>Prev</span>
				</PaginationLink>
			</PaginationItem>
			{pageNumbers.map((item) => {
				return (
					<PaginationItem className={currentPage === item ? "active" : ""} key={item}>
						<PaginationLink
							tag="a"
							href="#pageitem"
							onClick={(ev) => {
								ev.preventDefault();
								paginate(item);
							}}
						>
							{item}
						</PaginationLink>
					</PaginationItem>
				);
			})}

			<PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>
				<PaginationLink
					className="page-link-next"
					onClick={(ev) => {
						ev.preventDefault();
						nextPage();
					}}
					href="#next"
				>
					<span>Next</span>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
					{/* <Icon name="chevrons-right" /> */}
				</PaginationLink>
			</PaginationItem>
		</Pagination>
	);
};
export default PaginationComponent;
