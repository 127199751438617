import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, FormGroup, Spinner } from 'reactstrap'
import { unSubscribers } from '../store/actions/userAction';

export default function Subscribers() {
    const dispatch = useDispatch()
    const [values, setValues] = useState({})
    const [loading, setLoading] = useState(false)
    const unsubscriber = useSelector(state => state.trans)

    const handleChange = (e, name) => {
        const newValues = { ...values };
        newValues[name] = e.target.value;
        setValues(newValues);
    };

    const getValue = code => {
        if (values) {
            if (values[code]) {
                return values[code];
            } else {
                return "";
            }
        }
        return "";
    };

    const handleDone = (e) => {
        e.preventDefault()

        setLoading(true)
        dispatch(unSubscribers(values))
    }

    useEffect(() => {
        if (unsubscriber.subscriber) {
            setLoading(false)
        }
    }, [unsubscriber.subscriber])

    useEffect(() => {
        if (unsubscriber.subscriber_error) {
            setLoading(false)
        }
    }, [unsubscriber.subscriber_error])

    console.log(unsubscriber.subscriber_error)

    return (
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ padding: "10px" }}>
                    <h3 style={{ textAlign: "center" }}>Account deletion from our mailing list</h3>
                    <p style={{ textAlign: "center" }}>Opting out entails gaining exemption from receiving our email notifications and will be deleted.</p>
                    {/* <Form className="is-alter" onSubmit={handleDone}> */}

                    <FormGroup>
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                                Email
                            </label>
                        </div>
                        <div className="form-control-wrap">
                            <input
                                type="email"
                                id={"default-0"}
                                name="email"
                                required={true}
                                value={getValue("email")}
                                onChange={e => handleChange(e, "email")}
                                placeholder="Enter email"
                                className={`form-control-lg form-control`}
                                style={{ height: "50px", width: "100%" }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Button
                            onClick={handleDone}
                            size="lg"
                            style={{ width: "", backgroundColor: "#FBB211" }}
                            className="btn-block"
                            type="submit"
                            color="primary"
                        >
                            {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                        </Button>
                    </FormGroup>
                    {/* </Form> */}
                </Card>
            </div>
        </div>
    )
}
