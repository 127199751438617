import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './containers/App/App';
import { BrowserRouter as Router } from 'react-router-dom'
import store from './store'
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./containers/App/App.css"
import 'react-toastify/dist/ReactToastify.min.css';
import './doc/scss/master.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'swiper/swiper.scss'
import 'react-modal-video/scss/modal-video.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId="456469082888-d266nlmvv7t1d9u3vs641en1dr5ecocq.apps.googleusercontent.com">
        {/* <GoogleOAuthProvider clientId='655366494267-ps7huk8c1u77cltvop417el79pk2iet5.apps.googleusercontent.com'> */}
            <Router basename={`/`}>
                <App />
            </Router>
        </GoogleOAuthProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
