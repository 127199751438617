import React, { Fragment, useState } from 'react';
import MainMenuThree from "../../components/MainMenuThree";
import BannerSectionThree from "../../components/BannerSectionThree";
import { Link } from "react-router-dom";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Fade, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../components/BreadCrumb";
import CategoryFour from "../../components/CategoryFour";
import author1 from '../../doc/img/author/author1.png';

const AboutUsThreePage = () => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <Fragment>
            {/* <MainMenuThree /> */}
            <BreadCrumb className="shadow5 layout3" title="about">
                <Fragment>
                    <div className="space-50" />
                    <div className="row">
                        <div className="col-12">
                            <div className="author_about">
                                <div className="author_img">
                                    <div className="author_wrap">
                                        <img src={author1} alt="author1" />
                                    </div>
                                </div>
                                <div className="author_content">
                                    {/* <Link to="/">QuomodoSoft</Link> */}
                                    <ul className="inline">
                                        <li>About us</li>
                                        {/* <li>{new Date()}</li> */}
                                    </ul>
                                </div>
                                <p>Experience the ultimate convenience in bill payment with Reload.ng – your trusted partner for seamless transactions. At Reload.ng, we redefine the bill payment experience, providing you with the best-in-class service that combines efficiency, security, and simplicity. Paying your bills has never been easier, thanks to our user-friendly platform that allows you to settle your electricity, data, cable, and other utility bills effortlessly.</p>
                                <br />
                                <p>Reload.ng stands out as the epitome of reliability, ensuring that your payments are processed swiftly and securely. With a commitment to customer satisfaction, we prioritize your convenience, offering a diverse range of payment options to suit your preferences. Say goodbye to the hassle of traditional bill payment methods and embrace the future with Reload.ng – where excellence meets ease.</p>
                                <br />
                                <p>Why settle for less when you can choose the best? Trust Reload.ng for all your bill payment needs and enjoy a seamless, efficient, and secure experience every time. Discover a world of convenience with Reload.ng – your go-to destination for unparalleled bill payment services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="space-50" />
                </Fragment>
            </BreadCrumb>
            <BannerSectionThree />
        </Fragment>
    );
};

export default AboutUsThreePage;