import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { hideAgentIntentModal, hideModal, openAgentIntentModal, submitModal } from "../../store/actions/modalSetup";
import { FormStructure } from "../../utils/FormStructure";
import {
  PaymentAgentIntent,
  PaymentIntent,
  UserWalletPaymentFinal,
  UserWalletPaymentInit,
  verifySmartcardNumber,
} from "../../store/actions/transaction";
import { useHistory } from "react-router-dom"
import { CircleLoader } from "react-spinners";
import { finalAgentPayment, finalPayment } from "../../store/actions/finalPayment";
import { hideLoader } from "../../store/actions";
import { toast } from "react-toastify";
import { txStatusQuery } from "../../store/actions/Coralpay";

export default function DefaultModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false)
  const modal = useSelector((state) => state.modalSet);
  const verify = useSelector((state) => state.modalSet.verify);
  const transSuccess = useSelector((state) => state.trans.success);
  // const lookUp = useSelector((state) => state.trans.);
  const transSu = useSelector((state) => state.trans.paymentIntent);
  const nonUserIntentDetails = useSelector((state) => state.trans.nonUserIntent);
  const walletUser = useSelector((state) => state.trans.walletUserInit);
  const wallet = useSelector(state => state.wallet.loaded)
  const agentWallet = useSelector(state => state.agentFunding.balance)
  const getAgent = useSelector(state => state.agentUser.getAgentData)
  const getUser = useSelector(state => state.authUser.user)
  const productData = useSelector(state => state.product.selectPro)
  const verifyError = useSelector(state => state.trans.error)
  const selectValue = useSelector((state) => state.trans.productData);
  const finaliseErr = useSelector(state => state.finalPayment.err)
  const finalPaymentData = useSelector(state => state.finalPayment)
  const paymentIntentErr = useSelector(state => state.trans.paymentIntentError)
  const userWalletIntentErr = useSelector(state => state.trans.walletUserInitErr)

  useEffect(() => {
    if (finalPaymentData.data) {
      dispatch(hideAgentIntentModal());
      // setOpen(false)
      setTimeout(() => {
        setLoader(false)
        toast.success("Transaction successful")
        dispatch(hideLoader());
      }, 3000);
      history.push(`${process.env.PUBLIC_URL}/receipt`)
    }
  }, [finalPaymentData.data])

  let d = transSuccess
    ? "Initialize payment"
    : verify
      ? "Verify details"
      : "Initialize payment";

  useEffect(() => {
    if (transSuccess) {
      setLoader(false);
      const email = localStorage.getItem("email") ? localStorage.getItem("email") : ""
      const phoneNumber = localStorage.getItem("phoneNumber") ? localStorage.getItem("phoneNumber") : ""
      dispatch(submitModal("email", email));
      dispatch(submitModal("phoneNumber", phoneNumber));
    }
  }, [transSuccess]);

  useEffect(() => {
    if (transSu || walletUser) {
      setLoader(false);
      dispatch(hideModal())
      // setOpen(true)
      dispatch(openAgentIntentModal())
    }
  }, [transSu || walletUser]);

  useEffect(() => {
    if (verifyError) {
      setLoader(false)
    }
  }, [verifyError])

  useEffect(() => {
    if (paymentIntentErr) {
      setLoader(false)
      toast.error(paymentIntentErr.data.message)
    }
  }, [paymentIntentErr || userWalletIntentErr])

  useEffect(() => {
    if (userWalletIntentErr) {
      setLoader(false)
      toast.error(userWalletIntentErr.data.message)
    }
  }, [userWalletIntentErr])

  const submitModalFunc = (e) => {
    e.preventDefault();
    setLoader(true);

    // console.log(modal)
    // if(modal.s)
    if (!transSuccess && verify) {
      const details = {
        product: modal.submit.product,
        billerCode: modal.submit.slug,
        accountNumber: modal.submit.customerId,
        extras: {
          billerSlug: productData.productId.productcategoryId.categoryname === "Electricity" ? modal.submit.productName : modal.submit.slug,
          customerId: modal.submit.customerId,
          productName: modal.submit.productName,
        },
      };

      console.log(productData)
      dispatch(verifySmartcardNumber(details));

      // if(transSuccess && transSuccess.account.extras === "Startimes") {
    } else {
      if (productData.productId.productcategoryId.categoryname === "Electricity") {
        const d = JSON.parse(transSuccess.data.dataObject)
        console.log(d);

        if (d.responseData.minPayableAmount <= JSON.parse(modal.submit.amount)) {
          const data = {
            productId: modal.productId,
            amount: modal.submit.amount,
            email: modal.submit.email,
            paymentMethod: "billpayflutter",
            description: productData.productId.description,
            channelRef: "web",
            references: [
              "Account Name",
              "Account Number",
              "Customer details",
              "customerName",
              "email",
              "packageSlug",
              "phoneNumber",
            ],
            referenceValues: {
              "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
              "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
              "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
              customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
              email: modal.submit.email,
              packageSlug: modal.productName ||
                (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                  (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                    (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                      "9mobile-VTU"))),
              phoneNumber: modal.submit.phoneNumber,
            },
          };

          dispatch(submitModal("paymentGate", "flutterwave"));
          dispatch(PaymentIntent(data))
        } else {
          setLoader(false)
          toast.error("Error: Your minimum payable amount is " + d.responseData.minPayableAmount)
        }
      } else {

        // console.log(modal)
        if (localStorage.getItem("amount") || modal.submit.amount && modal.submit.email) {
          const data = {
            productId: modal.productId,
            amount: modal.submit.amount ? modal.submit.amount : localStorage.getItem("amount"),
            email: modal.submit.email,
            paymentMethod: "billpayflutter",
            description: productData.productId.description,
            channelRef: "web",
            references: [
              "Account Name",
              "Account Number",
              "Customer details",
              "customerName",
              "email",
              "packageSlug",
              "phoneNumber",
            ],
            referenceValues: {
              "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
              "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
              "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
              customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
              email: modal.submit.email,
              packageSlug: modal.productName ||
                (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                  (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                    (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                      "9mobile-VTU"))),
              phoneNumber: modal.submit.phoneNumber,
            },
          };

          dispatch(submitModal("paymentGate", "flutterwave"));
          dispatch(PaymentIntent(data))
        } else {
          setLoader(false)
          toast.error("Kindly provide values for all fields.")
        }
      }

      // !localStorage.getItem("token") && !localStorage.getItem("agent_token") ? dispatch(PaymentIntent(data)) : localStorage.getItem("token") ? dispatch(UserWalletPaymentInit(data)) : dispatch(PaymentAgentIntent(data))
    }
  };

  const submitModalWallet = (e) => {
    e.preventDefault();
    setLoader(true);

    console.log(modal)
    if (!transSuccess && verify) {
      const details = {
        product: modal.submit.product,
        billerCode: modal.submit.slug,
        accountNumber: modal.submit.customerId,
        extras: {
          billerSlug: productData.productId.productcategoryId.categoryname === "Electricity" ? modal.submit.productName : modal.submit.slug,
          customerId: modal.submit.customerId,
          productName: modal.submit.productName,
        },
      };

      // console.log(productData)
      dispatch(verifySmartcardNumber(details));
    } else {
      // console.log(modal.submit);
      if (wallet) {
        if (wallet.data.currentBalance >= modal.submit.amount) {
          const data = {
            productId: modal.productId,
            amount: modal.submit.amount,
            email: modal.submit.email,
            paymentMethod: "billpayflutter",
            description: productData.productId.description,
            channelRef: "web",
            references: [
              "Account Name",
              "Account Number",
              "Customer details",
              "customerName",
              "email",
              "packageSlug",
              "phoneNumber",
            ],
            referenceValues: {
              "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
              "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
              "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
              customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
              email: modal.submit.email,
              packageSlug: modal.productName ||
                (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                  (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                    (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                      "9mobile-VTU"))),
              phoneNumber: modal.submit.phoneNumber,
            },
          };

          dispatch(UserWalletPaymentInit(data))
        } else {
          setTimeout(() => {
            setLoader(false);
            toast.error("Invalid amount passed, wallet amount low.")
          }, 3000);
        }
      }

      if (agentWallet) {
        if (agentWallet.data.currentBalance >= modal.submit.amount) {
          const agentData = {
            "amount": modal.submit.amount,
            "description": productData.productId.description,
            "paymentMethod": "billpaywallet",
            "productId": modal.productId,
            "email": modal.submit.email,
            "customerId": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
            "packageSlug": modal.productName ||
              (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                  (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                    "9mobile-VTU"))),
            "customerName": modal.submit.accountName ? modal.submit.accountName : "reload"
          }

          dispatch(PaymentAgentIntent(agentData))
        } else {
          setTimeout(() => {
            setLoader(false);
            toast.error("Invalid amount passed, wallet amount low.")
          }, 3000);
        }
      }
    }
  };

  const handleCoralpay = (e) => {
    e.preventDefault()

    if (localStorage.getItem("amount") || modal.submit.amount && modal.submit.email) {
      const data = {
        productId: modal.productId,
        amount: modal.submit.amount,
        email: modal.submit.email,
        paymentMethod: "billpayCoralpay",
        description: productData.productId.description,
        channelRef: "web",
        references: [
          "Account Name",
          "Account Number",
          "Customer details",
          "customerName",
          "email",
          "packageSlug",
          "phoneNumber",
        ],
        referenceValues: {
          "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
          "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
          "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
          customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
          email: modal.submit.email,
          packageSlug: modal.productName ||
            (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
              (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                  "9mobile-VTU"))),
          phoneNumber: modal.submit.phoneNumber,
        },
      };

      dispatch(submitModal("paymentGate", "coralpay"));
      dispatch(PaymentIntent(data))
    } else {
      setLoader(false)
      toast.error("Kindly provide values for all fields.")
    }

    // alert("working")
  }

  const submitFinal = (e) => {
    e.preventDefault();

    // console.log(modal.submit)
    const r = modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber

    setLoader(true)
    const data = {
      transRef: transSu ? transSu.transRef : walletUser ? walletUser.transRef : "",
      // accountNumber: localStorage.getItem("customerId")
      accountNumber: r
    }

    console.log(data)
    !localStorage.getItem("token") && !localStorage.getItem("agent_token") ? dispatch(finalPayment(data)) : localStorage.getItem("token") ? dispatch(UserWalletPaymentFinal(data)) : dispatch(finalAgentPayment(data))
    // dispatch(finalAgentPayment(data))
  }

  useEffect(() => {
    if (finaliseErr) {
      console.log(finaliseErr)
      setLoader(false)
      // dispatch(hideModal())
      // setOpen(false)
      dispatch(hideAgentIntentModal())
      history.push(`${process.env.PUBLIC_URL}/payment-failed`)
    }
  }, [finaliseErr])

  return (
    <div>
      <Modal isOpen={modal.open}>
        <ModalHeader>{d}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img width={130} src={`${modal.img}`} alt={`Image`} />
          </div>
          <FormStructure />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(hideModal());
              setLoader(false);
            }}
          >
            Cancel
          </Button>
          {loader ? (
            <Button disabled={true}>
              <CircleLoader size={30} color="#2E0968" />
            </Button>
          ) : (
            <>
              {
                !localStorage.getItem("token") && !localStorage.getItem("agent_token") ?
                  <>{transSuccess ?
                    <><Button style={{
                      background: "#fda94f",
                      borderRadius: "1px solid #fda94f",
                      color: "#000",
                    }}
                      onClick={submitModalFunc}>Pay with Flutterwave</Button>

                      {/* <Button style={{
                        background: "#fda94f",
                        borderRadius: "1px solid #fda94f",
                        color: "#000",
                      }}
                        onClick={handleCoralpay}>Pay with Coralpay</Button> */}
                    </> : modal.verify ? <Button style={{
                      background: "#fda94f",
                      borderRadius: "1px solid #fda94f",
                      color: "#000",
                    }}
                      onClick={submitModalFunc}>Verify</Button> : <><Button style={{
                        background: "#fda94f",
                        borderRadius: "1px solid #fda94f",
                        color: "#000",
                      }}
                        onClick={submitModalFunc}>Pay with Flutterwave</Button>
                      {/* <Button style={{
                              background: "#fda94f",
                              borderRadius: "1px solid #fda94f",
                              color: "#000",
                            }}
                              onClick={handleCoralpay}>Pay with Coralpay</Button> */}
                    </>}
                  </>
                  : <Button
                    style={{
                      background: "#fda94f",
                      borderRadius: "1px solid #fda94f",
                      color: "#000",
                    }}
                    onClick={submitModalWallet}
                  >
                    {transSuccess ? "Submit" : modal.verify ? "Verify" : "Pay with Wallet"}
                  </Button>
              }
              {/* {!localStorage.getItem("token") && !localStorage.getItem("agent_token") ? <Button
                style={{
                  background: "#fda94f",
                  borderRadius: "1px solid #fda94f",
                  color: "#000",
                }}
                onClick={submitModalFunc}
              >
                {transSuccess ? "Submit" : modal.verify ? "Verify" : "Pay with Flutterwave"}
              </Button> :
                <Button
                  style={{
                    background: "#fda94f",
                    borderRadius: "1px solid #fda94f",
                    color: "#000",
                  }}
                  onClick={submitModalWallet}
                >
                  {transSuccess ? "Submit" : modal.verify ? "Verify" : "Pay with Wallet"}
                </Button>} */}
            </>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal.openAgentIntent}>
        <ModalHeader>{d}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img width={130} src={`${modal.img}`} alt={`Image`} />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "20px" }}>
              <p><b>Email: </b></p>
              <p><b>Phone Number: </b></p>
              <p><b>Amount: </b></p>
              <p><b>Fee: </b></p>
              <p><b>Total: </b></p>
            </div>
            <div>
              <p>{modal.submit ? modal.submit.email : ""}</p>
              <p>{modal.submit ? modal.submit.phoneNumber : ""}</p>
              <p>{transSu ? transSu.result.amount : walletUser ? walletUser.result.amount : ""}</p>
              <p>{transSu ? transSu.result.fee : walletUser ? walletUser.result.fee : ""}</p>
              <p>{transSu ? transSu.result.totalAmount : walletUser ? walletUser.result.totalAmount : ""}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              // setOpen(false)
              dispatch(hideAgentIntentModal())
              setLoader(false);
            }}
          >
            Cancel
          </Button>
          {loader ? (
            <Button disabled={true}>
              <CircleLoader size={30} color="#2E0968" />
            </Button>
          ) : (
            <Button
              style={{
                background: "#fda94f",
                borderRadius: "1px solid #fda94f",
                color: "#000",
              }}
              onClick={submitFinal}
            >
              {transSuccess ? "Submit" : modal.verify ? "Verify" : "Submit"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
