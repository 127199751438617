import React, { Fragment, useEffect, useState } from "react";
import FontAwesome from "../uiStyle/FontAwesome";
import tempIcon from "../../doc/img/icon/temp.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchModal from "../SearchModal";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../pages/Login";
import Icon from "../icon/Icon";
import { dataValues } from "../../utils/dataValues";
import { FormComponent } from "../FormComponent";
import { toast } from "react-toastify";
import { FundWallet, walletfunding } from "../../store/actions/wallet";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { agentwalletfund, agentwalletfunding } from "../../store/actions/agentWallet";
// import { hideModal, openModal } from "../../store/actions/modalSetup";
import WidgetTab from "../WidgetTab";
import profile from "../../images/profile.png"
import ProfileTab from "../WidgetTab/Profile";
import { hideModal } from "../../store/actions/modalSetup";
import { hideLoader } from "../../store/actions";

const flutterConfig = (transRef, paymentAmount, email, customerName) => ({
  public_key: `${process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY}`,
  tx_ref: transRef,
  amount: paymentAmount,
  currency: "NGN",
  payment_options: "card",
  trackingNo: transRef,
  customer: {
    email: email,
    name: customerName,
  },
  customizations: {
    title: "Reload.ng",
    description: "Make all Bill Payments",
    logo: "https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/reloadlogo.png",
  },
});

const MainMenu = ({ className, dark }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const history = useHistory()
  const [searchShow, setSearchShow] = useState(false);
  const [sideShow, setSideShow] = useState(false);
  const balanceData = useSelector(state => state.agentUser)
  const userdata = useSelector(state => state.authUser);
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [values, setValues] = useState({})
  const [loader, setLoader] = useState(false)
  const [fundingModal, setFundingModal] = useState(false)
  const [fund, setFund] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const walletFunding = useSelector(state => state.agentFunding.walletFunding)
  const balanceValue = useSelector(state => state.agentFunding.balance)
  // const [callFlutter, setCallFlutter] = useState(false)
  const wallet = useSelector(state => state.wallet.success)
  const walletNo = useSelector(state => state.wallet)
  const fundVerified = useSelector(state => state.wallet)
  const [balance, setBalance] = useState(false)
  const finaliseErr = useSelector(state => state.finalPayment.err)
  const updateAcc = useSelector(state => state.agentUser.updateProfile)
  const finalPaymentData = useSelector(state => state.finalPayment)
  const isUnsubscriberPage = location.pathname === '/reload/unsubscriber';


  useEffect(() => {
    if (finalPaymentData.data) {
      dispatch(hideModal());
      setOpen(false)
      setModalOpen(false)
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
      // history.push(`${process.env.PUBLIC_URL}/receipt`)
    }
  }, [finalPaymentData.data])

  useEffect(() => {
    if (updateAcc) {
      setOpenProfile(false)
    }
  }, [updateAcc])

  const handleChange = (e, name) => {
    const newValues = { ...values };
    newValues[name] = e.target.value;
    setValues(newValues);
  };

  const onFormSubmit = e => {
    e.preventDefault();

    setLoader(true);
    if (localStorage.getItem("token")) {
      if (values["amount"]) {
        // values["email"] = userdata.user.user.email
        values["paymentMethod"] = "billpayflutter"
        dispatch(walletfunding(values))
      } else {
        setLoader(false);
        toast.error("Please input all fields");
      }
    } else {
      if (values["amount"]) {
        values["email"] = balanceData.getAgentData ? balanceData.getAgentData.user.email : userdata.user.user.email
        dispatch(agentwalletfunding(values))
      } else {
        setLoader(false);
        toast.error("Please input all fields");
      }
    }
  };

  const handleWalletFunding = (e) => {
    e.preventDefault()

    if (!userdata.isAuthenticated) {
      setOpen(true)
    } else {
      setFundingModal(true)
    }
  }

  useEffect(() => {
    if (wallet) {
      setFundingModal(false)
      toast.success("Funding initiated")
      setFund(true)
      setLoader(false)
    }
  }, [wallet])

  useEffect(() => {
    if (walletFunding) {
      setLoader(false)
      setFund(true)
      setFundingModal(false)
    }
  }, [walletFunding])

  const handleFlutterPayment = useFlutterwave(
    flutterConfig(wallet ? wallet.data.reference ? wallet.data.reference : wallet.data.ref : walletFunding && walletFunding.data.reference, walletFunding ? walletFunding.data.amount : values["amount"], userdata.user ? userdata.user.user.email : balanceData.getAgentData && balanceData.getAgentData.user.email, userdata.user ? userdata.user.user.fullname : balanceData.getAgentData && balanceData.getAgentData.user.email)
  );

  useEffect(() => {
    if (fund === true) {
      handleFlutterPayment({
        callback: (response) => {
          toast.success("Account successfully funded.")
          console.log(walletFunding)
          const ref = {
            transRef: walletNo.success ? walletNo.success.data.reference : walletFunding && walletFunding.data.reference,
            paymentRef: response.transaction_id,
            paymentmethod: "billpaywallet",
          };

          // console.log(ref)
          { localStorage.getItem("token") ? dispatch(FundWallet(ref)) : dispatch(agentwalletfund(ref)) }
          setTimeout(() => {
            closePaymentModal();
          }, 2000);
        },
        onClose: () => {
          console.log("final payment working");
        },
      });
    }
  }, [fund]);

  useEffect(() => {
    if (fundVerified && fundVerified.verifyfunding) {
      const data = {
        amount: values["amount"],
        ref: wallet.data.ref
      }
      dispatch(FundWallet(data))
    }
  }, [fundVerified])

  useEffect(() => {
    if (balance) {
      // dispatch(WalletBalance(fundVerified.user.accountNo))
      setBalance(false)
    }
  }, [balance])

  const handleWallet = (e) => {
    e.preventDefault()

    if (!balanceData.getAgentData) {
      history.push(`${process.env.PUBLIC_URL}/`)
    } else {
      setFundingModal(true)
    }
  }

  const handlePayment = (e) => {
    e.preventDefault();

    setModalOpen(true)
    // dispatch(openModal("", ""));
  }

  useEffect(() => {
    if (finaliseErr) {
      console.log(finaliseErr)
      setModalOpen(false)
      history.push(`${process.env.PUBLIC_URL}/payment-failed`)
    }
  }, [finaliseErr])

  const handleLogout = (e) => {
    e.preventDefault()

    localStorage.clear()
    localStorage.removeItem("token")
    localStorage.removeItem("agent_token")
    history.push(`${process.env.PUBLIC_URL}/`)
    window.location.reload(false)
  }

  const handleProfile = (e) => {
    e.preventDefault()

    setOpenProfile(true)
  }

  const closeModalProfile = (e) => {
    e.preventDefault();

    setOpenProfile(false)
  }

  const closeModal = (e) => {
    e.preventDefault();

    setModalOpen(false)
  }

  const closeFundingModal = (e) => {
    e.preventDefault()

    setFundingModal(false)
  }

  const currentPath = location.pathname;
  console.log(currentPath)

  return (
    <Fragment>
      <div className={`main-menu ${className ? className : ""}`} id="header">
        <Link to="#top" className="up_btn up_btn1">
          <FontAwesome name="chevron-double-up" />
        </Link>
        <div className="main-nav clearfix is-ts-sticky">
          {isUnsubscriberPage ? "" :
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <p>To unsubscribe from our email service click <a href="https://reload.ng/reload/unsubscriber">here</a></p>
                </div>
                <div className="col-lg-8" style={{ display: "flex", justifyContent: "end" }}>
                  <div className="menu_right">
                    <div>
                      <p>{userdata.user ? userdata.user.user.email : balanceData.getAgentData ? balanceData.getAgentData.user.email : ""}</p>
                    </div>
                    <div>
                      <Button onClick={handlePayment}>Make payment</Button>
                    </div>
                    <div className="temp">
                      {currentPath === `${process.env.PUBLIC_URL}/about` ?
                        <p style={{ padding: "2px", color: "#fff" }} className="temp_count">Balance:
                          {fundVerified.loaded ? fundVerified.loaded.data ? "₦" + fundVerified.loaded.data.currentBalance : "" : balanceValue ? "₦" + balanceValue.data.currentBalance : "0.0"}
                        </p> :
                        <p style={{ padding: "2px" }} className="temp_count">Balance:
                          {fundVerified.loaded ? fundVerified.loaded.data ? "₦" + fundVerified.loaded.data.currentBalance : "" : balanceValue ? "₦" + balanceValue.data.currentBalance : "0.0"}
                        </p>}
                    </div>
                    <div className="temp">
                      {!localStorage.getItem("agent_token") ?
                        <Button color="primary" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }} onClick={handleWalletFunding}>Fund wallet</Button> : <Button color="primary" style={{ backgroundColor: "#FFCC28", color: "#fff", borderRadius: "1px solid #FFCC28" }} onClick={handleWallet}>Fund agent wallet</Button>}
                    </div>
                    {localStorage.getItem("agent_token") ?
                      <div style={{ cursor: "pointer" }} onClick={handleProfile}>
                        <img width="24" height="24" src={profile} alt="user--v1" />
                      </div>
                      : ""}
                    {localStorage.getItem("agent_token") || localStorage.getItem("token") ?
                      <span style={{ cursor: "pointer", marginLeft: "15px" }} onClick={handleLogout}>
                        <i class="fa fa-sign-out" style={{ width: "20px" }} aria-hidden="true"></i>
                      </span> : ""}
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setOpen(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <Login setOpen={setOpen} />
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={fundingModal} toggle={() => setFundingModal(false)}>
        <ModalBody>
          <div className="p-1">
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <button type="button" className="close" onClick={closeFundingModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h3>Wallet funding</h3>
            <FormComponent
              data={dataValues.funding}
              values={values}
              handleChange={handleChange}
              loading={loader}
              handleDone={onFormSubmit}
              ButtonName="Wallet Funding"
            />
          </div>
        </ModalBody>
      </Modal>
      {searchShow ? (
        <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
      ) : null}

      <Modal isOpen={openProfile}>
        <ModalBody>
          <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <button type="button" className="close" onClick={closeModalProfile}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Profile</h2>
          </div>

          <ProfileTab dark={true} />
        </ModalBody>
      </Modal>

      <Modal isOpen={modalOpen}>
        <ModalBody>
          <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Select Product</h2>
          </div>

          <WidgetTab dark={true} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default MainMenu;
