export const dataValues = {
  funding: [
    {
      name: "Amount",
      code: "amount",
      type: "number",
      error: "Enter amount",
      placeHolder: "Enter amount",
      required: true
    }
    // {
    //   name: "Narration",
    //   code: "narration",
    //   type: "text",
    //   error: "Enter Narration",
    //   placeHolder: "Enter Narration",
    //   required: true
    // }
  ],
  forgot: [
    {
      name: "Email",
      code: "email",
      type: "email",
      error: "Enter email",
      placeHolder: "enter email",
      required: true
    }
  ],
  reset: [
    {
      name: "New Password",
      placeHolder: "Enter password",
      code: "r-password",
      type: "password",
      error: "Enter a password",
      required: true,
    },
    {
      name: "Confirm Password",
      placeHolder: "Enter password",
      code: "r-cmPassword",
      type: "password",
      error: "Enter a password",
      required: true,
    },
  ],
  loginData: [
    {
      name: "Email address",
      placeHolder: "Enter email Address",
      code: "email",
      type: "email",
      error: "Enter Email address",
      required: true
    },
    {
      name: "Password",
      placeHolder: "Enter your password",
      code: "password",
      type: "password",
      error: "Enter your password.",
      required: true
    }
  ],
  forgotPass: [
    {
      name: "Phone number",
      placeHolder: "Enter Phone number",
      code: "phone",
      type: "phone",
      error: "Enter Phone number",
      required: true
    }
  ],
  registerData: [
    {
      name: "Full name",
      placeHolder: "Enter full name",
      code: "fullname",
      type: "text",
      error: "Enter Full name",
      required: true
    },
    {
      name: "Phone number",
      placeHolder: "Enter Phone number",
      code: "phone",
      type: "phone",
      error: "Enter Phone number",
      required: true
    },
    {
      name: "Email address",
      placeHolder: "Enter email Address",
      code: "email",
      type: "email",
      error: "Enter Email address",
      required: true
    },
    {
      name: "Password",
      placeHolder: "Enter your password",
      code: "password",
      type: "password",
      error: "Enter your password.",
      required: true
    }
  ],
  cableForm: [
    {
      name: "Customer Id",
      placeHolder: "Please enter customer id",
      code: "customerId",
      type: "text",
      error: "Please enter customer Id",
      required: true,
    },
  ],
  otherAirtimeForms: [
    {
      name: "email",
      placeHolder: "Please enter email address",
      code: "email",
      type: "email",
      error: "Please enter email address",
      required: true,
    },
    {
      name: "Phone Number",
      placeHolder: "Please enter Phone Number",
      code: "phoneNumber",
      type: "tel",
      error: "Please enter Phone Number",
      required: true,
    },
    {
      name: "Amount",
      placeHolder: "Please enter amount",
      code: "amount",
      type: "number",
      error: "Please enter amount",
      required: true,
    },
  ],
  changePassword: [
    {
      name: "Old password",
      placeHolder: "Please enter old password",
      code: "oldPassword",
      type: "password",
      error: "Please enter old password",
      required: true
    },
    {
      name: "New password",
      placeHolder: "Please enter new password",
      code: "newPassword",
      type: "password",
      error: "Please enter new password",
      required: true
    }
  ],
  updatePassword: [
    {
      name: "Full name",
      placeHolder: "Please enter Full name",
      code: "fullname",
      type: "text",
      error: "Please enter Full name",
      required: true
    },
    {
      name: "Address",
      placeHolder: "Please enter Address",
      code: "address",
      type: "text",
      error: "Please enter address",
      required: true
    },
    {
      name: "Phone",
      placeHolder: "Please enter phone",
      code: "phone",
      type: "text",
      error: "Please enter phone",
      required: true
    }
  ],
  otherCableForms: [
    {
      name: "Account Name",
      placeHolder: "Please enter account name",
      code: "accountName",
      type: "text",
      error: "Please enter account name",
      required: true,
    },
    {
      name: "Account Number",
      placeHolder: "Please enter account number",
      code: "accountNumber",
      type: "number",
      error: "Please enter account number",
      required: true,
    },
    {
      name: "email",
      placeHolder: "Please enter email address",
      code: "email",
      type: "email",
      error: "Please enter email address",
      required: true,
    },
    {
      name: "Phone Number",
      placeHolder: "Please enter Phone Number",
      code: "phoneNumber",
      type: "tel",
      error: "Please enter Phone Number",
      required: true,
    },
    {
      name: "Amount",
      placeHolder: "Please enter amount",
      code: "amount",
      type: "number",
      error: "Please enter amount",
      required: true,
    },
  ],
};
